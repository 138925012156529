import * as React from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import { useStyles, Menu } from "./style";

function SelectComponente({ id, label = <></>, opcoes, opcao = "", setOpcao }) {
  const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    setOpcao(value);
  };

  return (
    <FormControl sx={{ m: 0, width: "auto", border: "none" }}>
      <Select
        id={id}
        className={id}
        value={opcao}
        onChange={handleChange}
        label=""
        displayEmpty
        input={<OutlinedInput className={classes.input} />}
        renderValue={() => {
          return <>{label}</>;
        }}
        MenuProps={{ classes: { paper: classes.menu } }}
      >
        {opcoes.map((item) => (
          <Menu key={item.id} value={item.id} ativo={item?.id === opcao}>
            <ListItemText primary={item?.valor} />
          </Menu>
        ))}
      </Select>
    </FormControl>
  );
}

SelectComponente.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  opcoes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
      valor: PropTypes.string
    })
  ),
  opcao: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  setOpcao: PropTypes.func.isRequired
};

SelectComponente.defaultProps = {
  opcoes: [],
  label: <></>,
  opcao: ""
};

export default SelectComponente;
