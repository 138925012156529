import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import theme from "themes";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Select from "../Componentes/Select";
import { CelulaCheckbox, BotaoIcone, StyledTooltip } from "./style";

export default class ColunaFiltro {
  constructor(
    label,
    nome,
    opcoes,
    opcao,
    onSelecionar,
    onClickLinha,
    unidadesSelecionadas,
    campoSelecionar,
    minWidth = null,
    maxWidth = null
  ) {
    this.name = (
      <Select
        id={nome}
        name={nome}
        label={<strong>{label}</strong>}
        opcao={opcao}
        opcoes={opcoes}
        setOpcao={(item) => {
          onSelecionar(item);
        }}
      />
    );

    const retornarCelula = (parametros) => {
      return (
        <CelulaCheckbox cor="transparent">
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(
                  unidadesSelecionadas?.find(
                    (unidade) => parametros[campoSelecionar || "id"] === unidade
                  )
                )}
                onChange={() =>
                  onClickLinha([parametros[campoSelecionar || "id"]])
                }
                sx={{
                  color: `${theme.color.secondary} !important`,
                  "&.Mui-checked": {
                    color: `${theme.color.secondary} !important`
                  }
                }}
                icon={
                  parametros?.disabled ? (
                    <IndeterminateCheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )
                }
                disabled={parametros?.disabled}
              />
            }
            label={parametros[nome]}
          />
          {parametros?.editarUnidade && (
            <StyledTooltip
              title={parametros.tooltipTitulo}
              placement="top"
              arrow
            >
              <BotaoIcone
                type="button"
                onClick={() => parametros?.editarUnidade()}
              >
                <WarningAmberIcon />
              </BotaoIcone>
            </StyledTooltip>
          )}
        </CelulaCheckbox>
      );
    };

    this.selector = label;
    this.cell = (row) => retornarCelula(row);
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
  }
}
