import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import {
  obterUnidadesMigracaoPaginado,
  obterDistribuidorasMigracao,
  iniciarMigracaoLote
} from "servicos/atividadesServico";

export default class IniciarMigracaoHelper {
  static async listarDistribuidorasMigracao(setNotificacao) {
    try {
      const resultado = await obterDistribuidorasMigracao();

      if (resultado.status === 204) {
        return RetornoEndpointDto.Sucesso(
          "Listagem de distribuidoras obtidas com sucesso!",
          []
        );
      }

      return RetornoEndpointDto.Sucesso(
        "Listagem de distribuidoras obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Ocorreu um erro ao obter distribuidoras!"
      });
    }
  }

  static async listarUnidadesMigracaoPaginado(filtro, parametrosAdicionais) {
    try {
      const resultado = await obterUnidadesMigracaoPaginado(
        filtro,
        parametrosAdicionais
      );

      if (resultado.status === 204) {
        return RetornoEndpointDto.Sucesso(
          "Listagem de unidades obtidas com sucesso!",
          []
        );
      }

      return RetornoEndpointDto.Sucesso(
        "Listagem de unidades obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu um erro ao obter a lista de unidades!"
      );
    }
  }

  static async iniciarMigracao(dados, setNotificacao) {
    try {
      const resultado = await iniciarMigracaoLote(dados);

      return RetornoEndpointDto.Sucesso(
        "Lote migrado com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: error?.response?.data?.message || "Ocorreu um erro ao migrar!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu um erro ao migrar!"
      );
    }
  }
}
