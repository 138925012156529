/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback } from "react";
import { SelectArredondado, Loader, Botao, ModalConfirmacao, Tooltip } from "componentes";
import ModalInformacao from "componentes/modalVerificacao";
import BotaoRetornarListagem from "componentes/botaoRetornarListagem";
import moment from "moment";
import { FormControl, Grid, InputLabel, Select, MenuItem, OutlinedInput,Typography} from "@mui/material";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { obterCenarios } from "../../../servicos/cenariosSeriesHistoricas";
import { buscarPorFiltro} from "../../../servicos/seriesHistoricas";
import { getExcelCotacoesSeries} from "../../../servicos/exportacoes";
import { useStyles, Container } from "./style";
import { Base } from "componentes/cores";



const ExportarSerieHistorica = () => {

  const classes = useStyles();

  const [seriesHistoricas, setSeriesHistoricas] = useState([]);
  const [seriesSelecionadas, setSeriesSelecionadas] = useState([]);
  const [cenarios, setCenarios] = useState([]);
  const [cenario, setCenario] = useState();
  const [standard, setStandard] = useState("");
  const [showModalGerarExcel, setShowModalGerarExcel] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [msgFalha, setMsgFalha] = useState();
  const [nomeArquivo, setNomeArquivo] = useState("CotacoesSeries.xlsx");

 
  const handleSeriesHistoricas = async () => {
    try {
      const series = await buscarPorFiltro(true);
      if (series?.status === 200 && series?.data) {
        setSeriesHistoricas(series?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const handleCenarios = async () => {
    try {
      const lista = await obterCenarios();
      if (lista?.status === 200 && lista?.data) {
        const data = lista?.data;
        data.map(item => item.isStandard === 1 ? setStandard(item.id) : setStandard(""));

        setCenarios(lista?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }

  const handleExportExcel =  useCallback (async() => {
    setIsDownloading(true);

    const listaseries = seriesSelecionadas.map((item) => {
            return item
        }).join(",");

    if(listaseries.length > 0)
    {
        try
        {
            const response = await getExcelCotacoesSeries(listaseries, cenario, nomeArquivo);

            setSeriesSelecionadas([]);
            setShowModalGerarExcel(false);
            setIsDownloading(false);

            if(response !== "Sucesso")
            {
                setMsgFalha(`${response}`);
                setModalInfo(true);
            }
            else
            {
                store.dispatch(
                    alertaExibir({
                        tipo: "success",  
                        mensagem: "Exportação concluída com sucesso."
                    })
                );
            }

        }
        catch(error)
        {
            setShowModalGerarExcel(false);
            setMsgFalha(`Erro não previsto: ${error}`);
            setModalInfo(true);
        }
    }

});  

  const handleSelecaoSerie = async (value) => {
    if (value.includes("TODAS")) {
      setNomeArquivo(`Cotações - TODAS AS SÉRIES`);
      setSeriesSelecionadas(seriesHistoricas.map((it) => it.idSerieHistorica));
    } else {
      setNomeArquivo(`Cotações - ${seriesHistoricas.find(u => u.idSerieHistorica == value[0])?.codigo}`);
      setSeriesSelecionadas(value);
    }
  }
  

  useEffect(() => {
    handleSeriesHistoricas();
    handleCenarios();
  }, []);

  

  return <>
          <ModalInformacao
              titulo={"Exportação não realizada com a seguinte falha:"}
              exibir={modalInfo}
              mensagem={msgFalha}
              onClose={() => {
              setModalInfo(false);
              }}
              showBotao={false}
          />        

          <ModalConfirmacao
          item={showModalGerarExcel}
          onCancelar={() => setShowModalGerarExcel(false)}
          onConfirmar={() => handleExportExcel()}
          mensagem={
              <pre>
                  <Loader loading={isDownloading} className="w-auto">
                  <strong><p style={{fontSize: "12pt"}}>Gerar planilha excel com Cotações das Séries Históricas<br/> 
                  selecionadas e fazer download?</p></strong>
                  </Loader>            
                  <FormControl variant="outlined" >
                      <InputLabel htmlFor="outlined-nome-arquivo-excel">Nome do Arquivo</InputLabel>
                          <OutlinedInput
                              id="outlined-nome-arquivo-excel"
                              type="text"
                              value={nomeArquivo}
                              onChange={(e) => setNomeArquivo(e.target.value)}
                              label="Nome do Arquivo a Gerar"
                          />               

                  </FormControl>
              </pre>
          }
          /> 

    <Grid container spacing={3} className="mb-3">
      <Grid item sm={12}>
        <Container>
        <Grid container spacing={3} alignItems="center" style={{paddingLeft: 10, paddingBottom: 40}}>
                  <Grid item xs={4}>
                  <Typography variant = "h6" style={{color: Base.OffWhite, fontSize: 20}}>
                      Exportar Séries Históricas</Typography>
                  </Grid>
                  <Grid item xs={7}></Grid>
                  <Grid item xs={1} className={classes.backButton}>
                          <BotaoRetornarListagem voltarPagina />
                  </Grid>
          </Grid>
          <Grid container className="m-0 w-100" spacing={3}>
            <Grid item sm={9} className={classes.formControl}>
              <InputLabel id="serielabel">Séries Históricas</InputLabel>
              <Select
                variant="standard"
                labelId="serielabel"
                id="serie"
                value={seriesSelecionadas ?? ""}
                onChange={(event) => {handleSelecaoSerie(event.target.value)}}
                label="Série Histórica"
                fullWidth
                multiple>
                  <MenuItem value="TODAS">TODAS</MenuItem>
                  {seriesHistoricas.map((it) => (
                      <MenuItem key={it.idSerieHistorica} value={it.idSerieHistorica}>{it.codigo}</MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item sm={3}></Grid>
          </Grid>

          <Grid container item sm={12} className="m-0 w-100" spacing={3} direction="column">
                <Grid item sm={3}>
                  <Grid item sm={12} className={classes.dados}>
                    Cenário
                      <Grid item className={classes.formControl}>
                      <SelectArredondado
                        id="cenarioSelecionado"
                        name="cenarioSelecionado"
                        valueKey="id"
                        valueName="nome"
                        dataSource={cenarios}
                        value={standard ?? cenario}
                        onChange={(event) => {
                          setCenario(event.target.value);
                          setStandard(null);
                        }}
                      />
                      </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={2} className={classes.container} style={{paddingBottom:"30px"}}>
                  <Botao
                    type="button"
                    label="Gerar Excel"
                    color={Base.Mango}
                    className={classes.button}
                    onClick={()=>{setShowModalGerarExcel(true)}}
                    disabled = {seriesSelecionadas.length === 0 || cenario === undefined}
                  />
                </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  </>;
};

export default ExportarSerieHistorica;
