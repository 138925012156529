import React, { useState, useEffect, useRef } from "react";
import { Grid, Checkbox, Box } from "@mui/material";
import { Loader, Notificacao, SelectMenu } from "componentes";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import SelectArredondado from "componentes/selectArredondado";
import MaterialInputBusca from "componentes/inputBusca";
import MaterialInputMascara from "componentes/inputTextoMascara";
import Botao from "componentes/botao";
import theme from "themes";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { store } from "global/redux";
import { desabilitarUnidade } from "global/redux/modulos/usuario/actions";
import FiltroAdicionalTabela from "./filtroAdicionalTabela";
import TabelaDto from "./tabelaDto";
import ColunaFiltroCheckbox from "../ColunaCustomizada/colunaFiltroCheckbox";
import ColunaFiltroOpcoes from "../ColunaCustomizada/colunaFiltroSelectOpcoes";
import IniciarMigracaoHelper from "./helper";
import ModalCancelamento from "../../AcompanhamentoCliente/Componentes/ModalCancelamento";
import FiltroUnidades from "../Componentes/FiltroUnidades";
import TabConteudo from "../Componentes/TabConteudo";
import FormularioIniciarMigracao from "../Componentes/FormularioIniciarMigracao";
import { useStyles, StyledTabs, StyledTab } from "./style";

const IniciarMigracao = () => {
  const classes = useStyles();
  const refTabela = useRef(null);
  const [carregandoUnidades, setCarregandoUnidades] = useState();
  const [campo, setCampo] = useState("1");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [filtroSelecionado, setFiltroSelecionado] = useState(false);
  const [textoBusca, setTextoBusca] = useState("");
  const [distribuidoraId, setDistribuidoraId] = useState();
  const [distribuidoraLista, setDistribuidoraLista] = useState([]);
  const [labelTextoBusca, setLabelTextoBusca] = useState("");
  const [unidadesSelecionadas, setUnidadesSelecionadas] = useState([]);
  const [unidadeIds, setUnidadeIds] = useState([]);
  const [clicouCheckbox, setClickedCheckbox] = useState(false);
  const [dadosTabela, setDadosTabela] = useState();
  const [todosHabilitado, setTodosHabilitado] = useState(false);
  const [modalFiltrarUnidade, setModalFiltrarUnidade] = useState(false);
  const [modalIniciarMigracao, setModalIniciarMigracao] = useState(false);
  const [tab, setTab] = useState(0);
  const [ucsNaoEncontradas, setUcsNaoEncontradas] = useState();
  const [mascaraCpfCnpj, setMascaraCpfCnpj] = useState("###.###.###-##");
  const [notificacao, setNotificacao] = useState();
  const [dadosProcessamento, setDadosProcessamento] = useState();
  const [clicouBuscar, setClicouBuscar] = useState(false);
  const [mensagemUnidade, setMensagemUnidade] = useState("");
  const [statusId, setStatusId] = useState();
  const [associacaoId, setAssociacaoId] = useState();
  const [filtroOpcoes, setFiltroOpcoes] = useState([
    { id: -2, valor: "nenhum" },
    { id: -1, valor: "TODOS" }
  ]);
  const filtroUnidade = [
    { id: 0, valor: "TODOS" },
    { id: 1, valor: "NÃO INICIOU MIGRAÇÃO" },
    { id: 2, valor: "ENCAMINHADO PARA ATENDIMENTO/PARCEIROS" },
    { id: 3, valor: "FALHA NA MIGRAÇÃO" }
  ];
  const [filtroAssociacao, setFiltroAssociacao] = useState([
    { id: -1, valor: "todos" }
  ]);
  const [buscarDistribuidoras, setBuscarDistribuidoras] = useState(false);
  const [clicouFiltroAssociacao, setClicouFiltroAssociacao] = useState(false);
  const [unidadeSelecionada, setUnidadeSelecionada] = useState();

  const handleChangeTab = (event, newValue) => {
    onClickLimpar(distribuidoraId);
    setTab(newValue);
  };

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        unidadeIds,
        unidadesSelecionadas,
        statusId,
        associacaoId
      )
    );
  };

  const filtrarUnidades = (unidades) => {
    setClicouBuscar(true);
    setUnidadeIds(unidades);
    handleAlterarFiltroLateral();
  };

  const handleAlterarStatus = (id) => {
    setClicouBuscar(true);
    setStatusId(id);
    handleAlterarFiltroLateral();
  };

  const handleAlterarAssociacao = (id) => {
    setClicouBuscar(true);
    setAssociacaoId(id);
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        unidadeIds,
        unidadesSelecionadas,
        statusId,
        id
      )
    );
  };

  const verificarMaiorUc = (unidades) => {
    const uniadesFiltradas = unidades?.filter((item) => item?.quantidade);
    const maiorQuantidade = Math.max(
      ...uniadesFiltradas.map((q) => q?.quantidade)
    );
    const itemMaior = uniadesFiltradas.find(
      (item) => item.quantidade === maiorQuantidade
    );

    return itemMaior;
  };

  const obterDistribuidoras = async () => {
    const lista = await IniciarMigracaoHelper.listarDistribuidorasMigracao(
      setNotificacao
    );

    const listaConcatenada =
      lista?.data?.map((item) => {
        return {
          ...item,
          nomeFantasia: item?.quantidade
            ? `${item?.distribuidora} (${item?.quantidade})`
            : item?.distribuidora,
          desabilitado: !item?.quantidade || item?.quantidade === 0
        };
      }) || [];

    const listaOrdenada = listaConcatenada?.sort((a, b) =>
      a?.quantidade < b?.quantidade ? 1 : b?.quantidade < a?.quantidade ? -1 : 0
    );
    setBuscarDistribuidoras(false);
    setDistribuidoraLista([
      {
        distribuidoraId: "0",
        nomeFantasia: "TODOS"
      },
      ...listaOrdenada
    ]);
    setDistribuidoraId(verificarMaiorUc(listaOrdenada)?.distribuidoraId || "0");

    return listaOrdenada[0]?.distribuidoraId || 0;
  };

  const selecionarUnidades = (unidades) => {
    const unidadesFiltradas = unidadesSelecionadas?.filter((id) =>
      unidades.find((u) => u === id)
    );
    let novasUnidades;
    if (unidadesFiltradas?.length > 0) {
      novasUnidades = unidadesSelecionadas.filter((id) =>
        unidades.find((u) => u !== id)
      );
    } else {
      const unidadesSalvas = unidadesSelecionadas || [];
      novasUnidades = [...unidades, ...unidadesSalvas];
    }

    if (
      unidadesSelecionadas?.length > 0 &&
      novasUnidades?.length === dadosTabela?.data?.paginacao?.items?.length
    ) {
      setTodosHabilitado(true);
    } else {
      setTodosHabilitado(false);
    }

    setUnidadesSelecionadas(novasUnidades);
    setClickedCheckbox(true);
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        unidadeIds,
        novasUnidades
      )
    );
  };

  const selecionarTodasUnidades = () => {
    let todasSelecionadas = [];
    if (!todosHabilitado && dadosTabela?.data?.paginacao?.items?.length) {
      todasSelecionadas = dadosTabela?.data?.paginacao?.items?.map(
        (item) => item?.operacaoControleId
      );
      setTodosHabilitado(true);
    } else {
      setTodosHabilitado(false);
    }

    setUnidadesSelecionadas(todasSelecionadas);
    setClickedCheckbox(true);
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        distribuidoraId,
        textoBusca,
        unidadeIds,
        todasSelecionadas
      )
    );
  };

  const selecionarTodasAssociacoes = (id) => {
    setTodosHabilitado(true);
    setClicouFiltroAssociacao(true);
    setAssociacaoId(id);
    handleAlterarFiltroLateral();
  };

  const alterarFiltroSelecao = (opcao) => {
    if (opcao?.id === -1) {
      if (todosHabilitado) return;
      setTodosHabilitado(true);
      setClicouFiltroAssociacao(true);
      setAssociacaoId(-1);
      handleAlterarFiltroLateral();
      return;
    }
    if (opcao?.id === -2) {
      if (unidadesSelecionadas?.length === 0) return;
      setTodosHabilitado(false);
      setClicouFiltroAssociacao(true);
      setAssociacaoId(-2);
      handleAlterarFiltroLateral();
      return;
    }
    selecionarTodasAssociacoes(opcao?.id);
  };

  const handleIniciarMigracao = (id) => {
    setUnidadeSelecionada(id);
    setModalIniciarMigracao(true);
  };

  const colunas = [
    new ColunaFiltroCheckbox(
      "Nome da unidade",
      "nomeUnidade",
      filtroUnidade,
      statusId,
      handleAlterarStatus,
      selecionarUnidades,
      unidadesSelecionadas,
      "operacaoControleId"
    ),
    new Coluna("id", "Unidade consumidora"),
    new ColunaFiltroOpcoes(
      "Associação",
      "nomeAssociacao",
      filtroAssociacao,
      associacaoId,
      handleAlterarAssociacao,
      "operacaoControleId",
      handleIniciarMigracao
    )
  ];

  const retornarStatusTexto = () => {
    setDadosProcessamento();

    if (dadosProcessamento?.status === 1)
      return dadosProcessamento?.quantidade > 1
        ? `${dadosProcessamento.quantidade} UCs foram iniciadas para migração com sucesso!`
        : `${dadosProcessamento.quantidade} UC foi iniciada para migração com sucesso!`;

    return "UCs foram iniciadas para migração com sucesso!";
  };

  const retornarMensagemUnidade = (id) => {
    switch (id) {
      case 1:
        setMensagemUnidade(
          <Box>
            <h4>
              A unidade consumidora não está vinculada a esta distribuidora.
            </h4>
            <Botao
              label="Limpar filtro"
              className={classes.botaoFiltrarUc}
              onClick={() => {
                onClickLimpar("0");
              }}
              selected
            />
          </Box>
        );
        break;
      case 2:
        setMensagemUnidade(
          <Box>
            <h4>
              Esta unidade consumidora não foi encontrada. Por favor, tente
              novamente.
            </h4>
          </Box>
        );
        break;
      case 3:
        setMensagemUnidade(
          <Box>
            <h4>No momento, não existem UCs que não iniciaram migração</h4>
          </Box>
        );
        break;
      case 4:
        setMensagemUnidade(
          <Box>
            <h4>
              No momento, não existem UCs que foram encaminhados para
              atendimento/parceiros
            </h4>
          </Box>
        );
        break;
      case 5:
        setMensagemUnidade(
          <Box>
            <h4>No momento, não existem UCs que tiveram falha na migração</h4>
          </Box>
        );
        break;
      default:
        setMensagemUnidade(
          <Box>
            <h4>
              {tab === 1
                ? "No momento, não existem UCs em processamento."
                : "No momento, esta distribuidora não possui mais unidades consumidoras para iniciar o processo de migração."}
            </h4>
          </Box>
        );
    }
  };

  const obterOperacoes = async (parametros) => {
    setCarregandoUnidades(true);
    const parametrosBusca = parametros;

    const distribuidora =
      !distribuidoraId || buscarDistribuidoras
        ? await obterDistribuidoras()
        : 0;

    let parametrosFiltro = "";
    if (campo && textoBusca) {
      parametrosBusca.CampoBusca = campo === "5" ? "3" : campo;
      parametrosBusca.TextoBusca = textoBusca;
    }
    if ((distribuidoraId && distribuidoraId !== "0") || distribuidora)
      parametrosBusca.distribuidoraId = distribuidoraId || distribuidora;
    if (unidadeIds.length) {
      unidadeIds.map((unidade) => {
        parametrosFiltro += `&UnidadeIds=${unidade}`;
        return unidade;
      });
    }
    if (statusId === 2) {
      parametrosBusca.status = "Recusada/Negada";
    }
    if (statusId === 3) {
      parametrosBusca.status = "Falha";
    }
    if (associacaoId && associacaoId !== -1 && associacaoId !== -2) {
      parametrosBusca.associacaoId = associacaoId;
    }
    if (tab === 1) {
      parametrosBusca.status = "EmProcessamento";
    }
    let resultado;

    if (clicouCheckbox) {
      resultado = dadosTabela;
    } else {
      resultado = await IniciarMigracaoHelper.listarUnidadesMigracaoPaginado(
        parametrosBusca,
        parametrosFiltro
      );
      setDadosTabela(resultado);
    }

    let unidades = unidadeIds;

    if (
      campo === "1" &&
      distribuidoraId &&
      (textoBusca || unidadeIds?.length) &&
      resultado.sucesso &&
      resultado?.data?.paginacao?.items?.length === 0
    ) {
      delete parametrosBusca.distribuidoraId;
      const resultadoUnidade = await IniciarMigracaoHelper.listarUnidadesMigracaoPaginado(
        parametrosBusca,
        parametrosFiltro
      );

      if (
        resultadoUnidade?.sucesso &&
        resultadoUnidade?.data?.paginacao?.items?.length
      ) {
        unidades = [];
        setFiltroSelecionado(false);
        setModalFiltrarUnidade(false);
        retornarMensagemUnidade(1);
      } else retornarMensagemUnidade(2);
    } else setMensagemUnidade("");

    setClickedCheckbox(false);
    setCarregandoUnidades(false);

    if (!resultado.sucesso) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: resultado?.mensagem || "Ocorreu um erro ao obter dados!"
      });

      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    let unidadesSelecionar = unidadesSelecionadas;
    const unidadeIdNaoEncontradas = !resultado?.data?.paginacao?.items.length
      ? unidades
      : resultado?.data?.unidadesNaoEncontrados;
    let selecionarTodasUnidades = true;

    if (unidades?.length) {
      if (unidadeIdNaoEncontradas?.length) {
        if (unidadeIdNaoEncontradas?.length > 1) {
          const unidadesFormatadas = unidadeIdNaoEncontradas?.map(
            (unidade, index) => {
              if (index === unidadeIdNaoEncontradas?.length - 1) return unidade;
              return `${unidade}, `;
            }
          );
          setUcsNaoEncontradas(
            <Box>
              As unidades consumidoras (<strong>{unidadesFormatadas}</strong>)
              não foram encontradas. Por favor, verifique e tente novamente.
            </Box>
          );
        } else {
          setUcsNaoEncontradas(
            <Box>
              A unidade consumidora (<strong>{unidadeIdNaoEncontradas}</strong>)
              não foi encontrada. Por favor, verifique e tente novamente.
            </Box>
          );
        }
        selecionarTodasUnidades = false;
        resultado = dadosTabela;
        setDadosTabela(resultado);
      } else {
        setUcsNaoEncontradas();
        setModalFiltrarUnidade(false);
      }

      if (
        resultado?.data?.paginacao?.items?.length &&
        !clicouCheckbox &&
        selecionarTodasUnidades &&
        !clicouFiltroAssociacao
      ) {
        setTodosHabilitado(true);
        unidadesSelecionar = resultado?.data?.paginacao?.items?.map(
          (unidade) => unidade?.operacaoControleId
        );
        setUnidadesSelecionadas(unidadesSelecionar);
      }

      const novasUnidadesIds = unidades.filter((id) =>
        resultado?.data?.paginacao?.items.find(
          (unidade) => unidade?.id === Number(id)
        )
      );
      setUnidadeIds(novasUnidadesIds);
    }

    if (resultado?.data?.paginacao?.items?.length === 0) {
      setUnidadesSelecionadas([]);
      setTodosHabilitado(false);
      if (tab === 1 && dadosProcessamento) {
        setNotificacao({
          aberto: true,
          tipo: "success",
          texto: retornarStatusTexto()
        });
      }
      if (statusId === 1) {
        retornarMensagemUnidade(3);
      }
      if (statusId === 2) {
        retornarMensagemUnidade(4);
      }
      if (statusId === 3) {
        retornarMensagemUnidade(5);
      }
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (clicouFiltroAssociacao) {
      let selecionadas = [];

      if (associacaoId === -1) {
        resultado?.data?.paginacao?.items?.map((item) => {
          selecionadas.push(item?.operacaoControleId);
          return item;
        });
      } else if (associacaoId === -2) {
        selecionadas = [];
      } else {
        resultado?.data?.paginacao?.items?.map((item) => {
          if (item?.associacaoId === associacaoId) {
            selecionadas.push(item?.operacaoControleId);
          }
          return item;
        });
      }

      setUnidadesSelecionadas(selecionadas);
    }

    setClicouFiltroAssociacao(false);

    if (resultado?.data?.associacoesLuz?.length) {
      const associacoesLuz =
        resultado?.data?.associacoesLuz?.map((associacao) => {
          return {
            ...associacao,
            valor: associacao?.nomeFantasia?.toUpperCase()
          };
        }) || [];

      setFiltroAssociacao([{ id: 0, valor: "TODOS" }, ...associacoesLuz]);

      setFiltroOpcoes([
        { id: -1, valor: "todos" },
        { id: -2, valor: "nenhum" },
        ...associacoesLuz
      ]);
    }

    const resLinhas = await resultado?.data?.paginacao?.items?.map((res) => {
      return new TabelaDto(
        res?.nomeUnidade,
        res?.id,
        res?.nomeAssociacao,
        tab === 1,
        res?.operacaoControleId,
        res?.associacaoId
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginacao.pageSize,
      resultado.data.paginacao.totalCount
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterOperacoes({
      ItensPorPagina: parametros.totalPagina,
      Pagina: parametros.pagina
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(distribuidoraId, textoBusca, null, null)
    );
  };

  const onClickBuscar = () => {
    setClicouBuscar(true);
    handleClickAtivarFiltro();
  };

  const habilitarLimparFiltro = () => {
    if ((unidadeIds.length || textoBusca || distribuidoraId) && clicouBuscar)
      setFiltroSelecionado(true);
    else setFiltroSelecionado(false);
  };

  const onClickLimpar = (distribuidora = null) => {
    setCampo("1");
    setTextoBusca("");
    setDistribuidoraId(
      distribuidora ||
        verificarMaiorUc(distribuidoraLista)?.distribuidoraId ||
        "0"
    );
    setUnidadeSelecionada();
    setUnidadesSelecionadas([]);
    setTodosHabilitado(false);
    setUnidadeIds([]);
    setFiltroSelecionado(false);
    setClicouBuscar(false);
    setMensagemUnidade("");
    setAssociacaoId();
    setStatusId();
    setClicouFiltroAssociacao(false);
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(distribuidora || distribuidoraId)
    );
  };

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  useEffect(() => {
    habilitarLimparFiltro();
  }, [unidadeIds, clicouBuscar]);

  useEffect(() => {
    setTextoBusca("");

    if (campo === "3") {
      setLabelTextoBusca("Pesquisar por CPF");
      setMascaraCpfCnpj("###.###.###-##");
    } else if (campo === "5") {
      setLabelTextoBusca("Pesquisar por CNPJ");
      setMascaraCpfCnpj("##.###.###/####-##");
    } else if (campo === "4") {
      setLabelTextoBusca("Pesquisar por número de instalação");
      setMascaraCpfCnpj(null);
    } else if (campo === "2") {
      setLabelTextoBusca("Pesquisar por nome do cliente");
    } else if (campo === "1") {
      setLabelTextoBusca("Pesquisar por nome da unidade");
    }
  }, [campo]);

  return (
    <Loader loading={carregandoUnidades} className="w-auto">
      <Notificacao fechar={() => setNotificacao({})} mensagem={notificacao} />
      <ModalCancelamento
        titulo={ucsNaoEncontradas ? "UC não encontrada!" : "Filtrar por UC"}
        item={modalFiltrarUnidade}
        conteudo={
          <FiltroUnidades
            textoNaoEncontrado={ucsNaoEncontradas}
            onFiltrar={(ucs) => {
              if (ucsNaoEncontradas) {
                if (ucs?.length === 0) {
                  handleAlterarFiltroLateral();
                }
                setUcsNaoEncontradas();
                return;
              }
              filtrarUnidades(ucs);
            }}
            onCancelar={() => {
              setModalFiltrarUnidade(false);
              setUcsNaoEncontradas();
            }}
            unidades={unidadeIds}
            setUnidades={setUnidadeIds}
            inputType="number"
            carregando={carregandoUnidades}
          />
        }
        onCancelar={() => {
          setModalFiltrarUnidade(false);
          setUcsNaoEncontradas();
        }}
      />
      <ModalCancelamento
        titulo="Iniciar migração"
        item={modalIniciarMigracao}
        conteudo={
          <FormularioIniciarMigracao
            unidadeSelecionada={unidadeSelecionada}
            unidadesSelecionadas={unidadesSelecionadas}
            onCancelar={() => {
              setModalIniciarMigracao(false);
              setUnidadeSelecionada();
            }}
            setCarregando={setCarregandoUnidades}
            obterDados={(dadosProcessamento) => {
              onClickLimpar(distribuidoraId);
              setDadosProcessamento(dadosProcessamento);
              setBuscarDistribuidoras(true);
            }}
            setNotificacao={setNotificacao}
          />
        }
        onCancelar={() => {
          setModalIniciarMigracao(false);
          setUnidadeSelecionada();
        }}
      />
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <h2 className={classes.titulo}>Iniciar migração</h2>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <SelectArredondado
            select
            id="Distribuidora"
            name="Distribuidora"
            label="Distribuidora"
            placeholder="Distribuidora"
            valueKey="distribuidoraId"
            valueName="nomeFantasia"
            className={classes.select}
            dataSource={distribuidoraLista}
            value={distribuidoraId}
            onChange={(e) => setDistribuidoraId(e.target.value)}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <SelectArredondado
            select
            id="campo"
            name="campo"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "NOME DA UNIDADE", valor: "1" },
              { nome: "NOME DO CLIENTE", valor: "2" },
              { nome: "CPF DA UNIDADE", valor: "3" },
              { nome: "CNPJ DA UNIDADE", valor: "5" },
              { nome: "NÚMERO DE INSTALAÇÃO", valor: "4" }
            ]}
            value={campo}
            onChange={(e) => setCampo(e.target.value)}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        {campo === "1" || campo === "2" || campo === "4" ? (
          <Grid item md={3} sm={6} xs={12}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label={labelTextoBusca}
              className={classes.search}
              cleanAdornment
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca}
              onInput={(e) => setTextoBusca(e.target.value)}
              onClickLimpar={() => setTextoBusca("")}
            />
          </Grid>
        ) : (
          <Grid item lg={4} md={4} sm={6} xs={12} className="font-weight-bold">
            <MaterialInputMascara
              type="text"
              id="textoBusca"
              name="textoBusca"
              label={labelTextoBusca}
              mask={mascaraCpfCnpj}
              value={textoBusca}
              renderIconShowHide={false}
              className={classes.search}
              onInputChange={(valor) => setTextoBusca(valor)}
            />
          </Grid>
        )}
        <Grid item lg={2} md={3} sm={6} xs={12}>
          <Botao label="Buscar" onClick={() => onClickBuscar()} />
        </Grid>
        <Grid container item xs={12}>
          <Box className={classes.box} pr={2}>
            <Checkbox
              checked={todosHabilitado}
              onChange={() => selecionarTodasUnidades()}
              sx={{
                color: `${theme.color.secondary} !important`,
                padding: "8px 0 !important",
                "&.Mui-checked": {
                  color: `${theme.color.secondary} !important`
                }
              }}
              icon={
                tab === 1 || !dadosTabela?.data?.paginacao?.items?.length ? (
                  <IndeterminateCheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              disabled={
                tab === 1 || !dadosTabela?.data?.paginacao?.items?.length
              }
            />
            <SelectMenu
              opcoes={filtroOpcoes}
              placement="bottom-start"
              onChange={(opcao) => alterarFiltroSelecao(opcao)}
              className={classes.selectFiltrar}
              campoDescricao="valor"
            />
          </Box>
          <Box className={classes.box}>
            <Botao
              label="Filtrar por UC"
              className={classes.botaoFiltrarUc}
              onClick={() => setModalFiltrarUnidade(true)}
              selected
              disabled={
                tab === 1 || !dadosTabela?.data?.paginacao?.items?.length
              }
            />
          </Box>
          {filtroSelecionado && (
            <Box className={classes.box}>
              <Botao
                label="Limpar filtro"
                className={classes.botaoLimpar}
                onClick={() => {
                  onClickLimpar(distribuidoraId);
                }}
                selected
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {unidadesSelecionadas?.length > 0 && (
        <Grid container className={classes.boxAtualizarStatus} mb={3}>
          <Grid container item xs={3} />
          <Grid
            container
            item
            xs={6}
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <span>
              {unidadesSelecionadas?.length}{" "}
              {unidadesSelecionadas?.length > 1
                ? "unidades consumidoras selecionados"
                : "unidade consumidora selecionado"}
            </span>
          </Grid>
          <Grid
            container
            item
            xs={3}
            sx={{ alignItems: "center", justifyContent: "flex-end" }}
          >
            <Botao
              label="Iniciar migração"
              className={classes.buttonFiltro}
              onClick={() => setModalIniciarMigracao(true)}
            />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <StyledTabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="Tab"
            variant="fullWidth"
          >
            <StyledTab
              label="Em aberto"
              id="tab-0"
              aria-controls="tabpanel-0"
            />
            <StyledTab
              label="Em processamento"
              id="tab-1"
              aria-controls="tabpanel-1"
            />
          </StyledTabs>
        </Grid>
        <Grid item xs={12}>
          <TabConteudo value={tab} index={0}>
            <TabelaPaginada
              ref={refTabela}
              className={classes.tabela}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              colunas={colunas}
              filtrosAdicionais={filtroAdicionalTabela}
              paginaAtual={1}
              qtdLinhas={3}
              noHeader
              totalItens
              itemsPorPagina={[20, 50, 100]}
              noDataComponent={{
                texto: mensagemUnidade || (
                  <Box>
                    <h4>
                      No momento, esta distribuidora não possui mais unidades
                      consumidoras para iniciar o processo de migração.
                    </h4>
                  </Box>
                ),
                className: classes.naoEncontrado
              }}
              persistTableHead
            />
            <Box className={classes.naoEncontrado} />
          </TabConteudo>
          <TabConteudo value={tab} index={1}>
            <TabelaPaginada
              ref={refTabela}
              className={classes.tabela}
              onChangeFiltrosTabela={onChangeFiltrosTabela}
              colunas={colunas}
              filtrosAdicionais={filtroAdicionalTabela}
              paginaAtual={1}
              qtdLinhas={3}
              noHeader
              totalItens
              itemsPorPagina={[20, 50, 100]}
              noDataComponent={{
                texto: mensagemUnidade || (
                  <Box>
                    <h4>No momento, não existem UCs em processamento.</h4>
                  </Box>
                ),
                className: classes.naoEncontrado
              }}
              persistTableHead
            />
          </TabConteudo>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default IniciarMigracao;
