/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  SelectArredondado,
  TabelaPaginada,
  TabelaPaginadaMobile
} from "componentes";
import UnidadeNaoSelecionada from "componentes/unidadeNaoSelecionada";
import { useSelector } from "react-redux";
import theme from "themes";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import InputDataWhite from "../../../../componentes/inputTextoDataBranco";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import Loader from "../../../../componentes/loader";
import ColunaComponentePersonalizado from "../../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ColunaRelatorio from "../../../FinanceiroColaborador/Fatura/listagem/colunasPersonalizadas/ColunaRelatorio";
import {
  onClickGerarPDFMobile,
  onClickGerarBoletoMobile,
  onClickGerarNotaMobile
} from "./onClickGerarPDFMobile";
import enumeradorStatus from "../enumeradores/enumeradorStatus";
import ColunaBoleto from "./colunasPersonalizadas/ColunaBoleto";
import ColunaNotaFiscal from "./colunasPersonalizadas/ColunaNotaFiscal";
import ColunaStatus from "./colunasPersonalizadas/ColunaStatus";
import FaturasHelper from "./helper";

// DTO
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import FiltroDto from "./dto/filtroDto";
import listagemFaturasDto from "./dto/listagemFaturasDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { Button, useStyles } from "./style";

// Serviços
import {
  filtroStatusPor,
  listarOrganizarPor
} from "../../../../servicos/faturasServico";

const ListagemFaturas = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroDto()
  );

  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [filtroStatusSelecionado, setFiltroStatusSelecionado] = useState(0);
  const [valorOrdenacao, setValorOrdenacao] = useState(0);

  const classes = useStyles();
  const refTabela = useRef(null);

  const global = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = global;
  // eslint-disable-next-line no-underscore-dangle
  const theme_ = useTheme();
  const mobile = useMediaQuery(theme_.breakpoints.down("lg"));
  const [carregandoPdf, setCarregandoPDF] = useState(false);
  const colunas = [];

  // eslint-disable-next-line react/prop-types
  const ColunaMobile = ({ parametros }) => {
    return (
      <Box className={classes.boxArea}>
        <h5>{`Fatura ${parametros.mesAnoReferenciaFormatado}`}</h5>
        <p className={classes.valorFatura}>{parametros.valorFormatado}</p>
        <p className={classes.vencimentoFatura}>
          {`Vencimento ${parametros.dataVencimentoFormatada}`}
        </p>

        <Box className="d-flex justify-content-center">
          <Box
            className={`${classes.fundoCircular} ${
              parametros.statusId === enumeradorStatus.PENDENTE
                ? classes.fundoPendente
                : parametros.statusId === enumeradorStatus.ATRASADO
                ? classes.fundoAtrasado
                : parametros.statusId === enumeradorStatus.PAGO_PARCIAL
                ? classes.fundoPagoParcial
                : parametros.statusId === enumeradorStatus.PAGO
                ? classes.fundoPago
                : ""
            }`}
          />
          <Box className="d-inline">{parametros?.statusNome.toUpperCase()}</Box>
        </Box>
        <Box className="d-flex justify-content-center">
          {parametros?.dataPagamentoFormatada !== "--" &&
            `em: ${parametros?.dataPagamentoFormatada}`}
        </Box>

        <Box className="d-flex justify-content-center">
          <Button
            onClick={() => {
              onClickGerarPDFMobile(
                parametros.unidadeConsumidoraId,
                parametros.mesReferencia,
                parametros.anoReferencia,
                setCarregandoPDF
              );
              onClickGerarNotaMobile(parametros, setCarregandoPDF);
              onClickGerarBoletoMobile(parametros, setCarregandoPDF);
            }}
            className={`text-white ${classes.botao} mt-3`}
          >
            Baixar fatura
          </Button>
        </Box>
      </Box>
    );
  };

  if (!mobile) {
    colunas.push(
      new Coluna("mesAnoReferenciaFormatado", "Mês referente", true)
    );
    colunas.push(new Coluna("dataVencimentoFormatada", "Vencimento", true));
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "Status",
        ColunaStatus,
        null,
        false,
        true,
        "12%",
        "12%",
        "pl-2"
      )
    );
    colunas.push(new Coluna("dataPagamentoFormatada", "Pagamento", true));
    colunas.push(new Coluna("valorFormatado", "Valor sem ICMS", true));
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "NF",
        ColunaNotaFiscal,
        null,
        false,
        true,
        "6%",
        "6%",
        "pl-2"
      )
    );
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "Fat.",
        ColunaRelatorio,
        null,
        false,
        true,
        "6%",
        "6%",
        "pl-2"
      )
    );
    colunas.push(
      new ColunaComponentePersonalizado(
        "opcoes",
        "Bol.",
        ColunaBoleto,
        null,
        false,
        true,
        "6%",
        "6%",
        "pl-2"
      )
    );
  } else {
    colunas.push(
      new ColunaComponentePersonalizado(
        "nome",
        "",
        ColunaMobile,
        null,
        false,
        false,
        null,
        null
      )
    );
  }

  const obterGrupos = async (parametros) => {
    const filtro = new FiltroDto();
    filtro.linhasPorPagina = parametros?.tamanhoPagina;
    filtro.pagina = parametros?.pagina;
    filtro.ordenacao = Number(valorOrdenacao) !== 0 ? valorOrdenacao : 0;
    filtro.unidadeConsumidoraId =
      Number(unidadeSelecionada) !== 0 ? unidadeSelecionada : 0;
    filtro.anoReferencia = Number(ano) !== 0 ? ano : 0;
    filtro.mesReferencia = Number(mes) !== 0 ? mes : 0;
    filtro.statusId =
      Number(filtroStatusSelecionado) !== 0 ? filtroStatusSelecionado : 0;

    const resultado = await FaturasHelper.listarFaturas(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.lista?.map((res) => {
      // eslint-disable-next-line new-cap
      return new listagemFaturasDto(
        res.id,
        res.unidadeConsumidoraId,
        res.ultimoIdentificadorBoleto,
        res.ultimoNomeBoleto,
        res.ultimoIdentificadorNotaFiscal,
        res.ultimoNomeNotaFiscal,
        res.mesAnoReferenciaFormatado,
        res.mesReferencia,
        res.anoReferencia,
        res.statusNome,
        res.statusId,
        res.dataPagamentoFormatada,
        res.valorFormatado,
        res.dataVencimentoFormatada
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  // eslint-disable-next-line consistent-return
  const onChangeFiltrosTabela = async (parametros) => {
    if (Number(unidadeSelecionada) !== 0) {
      return obterGrupos({
        tamanhoPagina: parametros?.totalPagina,
        pagina: parametros?.pagina,
        filtroAdicional: new FiltroDto(
          parametros?.filtrosAdicionais?.ordenacao,
          parametros?.filtrosAdicionais?.statusId,
          parametros?.filtroAdicional?.anoReferencia,
          parametros?.filtroAdicional?.mesReferencia,
          parametros?.filtroAdicional?.unidadeConsumidoraId
        )
      });
    }
  };

  const handleClickAtivarFiltro = () => {
    setValorOrdenacao(ordemSelecionada);

    setFiltroAdicionalTabela(
      new FiltroDto(
        ordemSelecionada,
        filtroStatusSelecionado,
        ano,
        mes,
        unidadeSelecionada
      )
    );
  };

  useEffect(() => {
    setFiltroAdicionalTabela(
      new FiltroDto(
        ordemSelecionada,
        filtroStatusSelecionado,
        ano,
        mes,
        unidadeSelecionada
      )
    );
  }, [unidadeSelecionada, ano, mes]);

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;

    setOrdemColunaSelecionada(val);
    setOrdemSelecionada(valor);
  };

  const handleClickFiltroStatusSelecionado = (event) => {
    const val = filtroStatusSelecionado === event ? "" : event;
    setFiltroStatusSelecionado(val);
  };

  useEffect(() => {
    // Para mobile, iniciar com mês atual e ano atual
    const data = new Date();
    if (data.getMonth() === 0) {
      // Se janeiro marcar 12 (dezembro)
      setMes(12);
    }
    setMes(data.getMonth());
    setAno(data.getFullYear());
  }, [mobile]);

  useEffect(() => {
    if (mes === 0 && mobile) {
      // Se mês zero e estiver no mobile
      const data = new Date();
      if (data.getMonth() === 0) {
        // Se janeiro marcar 12 (dezembro)
        setMes(12);
      }
      setMes(data.getMonth());
    }
  }, [mes, setMes]);

  return (
    <>
      {unidadeSelecionada ? (
        <Grid container xs={12}>
          <Grid container item xs={12} className="ml-3 mr-2 mb-3">
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item md={2} xs={6}>
                <InputDataWhite
                  styleForm={false}
                  type="text"
                  id="Ano"
                  label="Ano"
                  format="yyyy"
                  customValue={ano === 0 ? null : new Date(ano, 1, 1)}
                  onChange={(e) => {
                    if (e && !Number.isNaN(e.getFullYear())) {
                      setAno(e.getFullYear());
                    } else {
                      setAno(0);
                    }
                  }}
                  views={["year"]}
                  maxDate={new Date(2090, 1, 1)}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <SelectArredondado
                  styleForm={false}
                  select
                  id="Mes"
                  name="Mes"
                  label="Mês"
                  valueKey="valor"
                  valueName="nome"
                  dataSource={[
                    {
                      nome: "Mês",
                      valor: "0"
                    },
                    {
                      nome: "Janeiro",
                      valor: 1
                    },
                    {
                      nome: "Fevereiro",
                      valor: 2
                    },
                    {
                      nome: "Março",
                      valor: 3
                    },
                    {
                      nome: "Abril",
                      valor: 4
                    },
                    {
                      nome: "Maio",
                      valor: 5
                    },
                    {
                      nome: "Junho",
                      valor: 6
                    },
                    {
                      nome: "Julho",
                      valor: 7
                    },
                    {
                      nome: "Agosto",
                      valor: 8
                    },
                    {
                      nome: "Setembro",
                      valor: 9
                    },
                    {
                      nome: "Outubro",
                      valor: 10
                    },
                    {
                      nome: "Novembro",
                      valor: 11
                    },
                    {
                      nome: "Dezembro",
                      valor: 12
                    }
                  ]}
                  value={mes}
                  onChange={(event) => {
                    setMes(event.target.value);
                  }}
                  permiteValorBranco
                  marginBottom="0"
                />
              </Grid>
              {!mobile && (
                <Grid
                  container
                  item
                  xs={6}
                  md={6}
                  sm={6}
                  spacing={2}
                  className={classes.itemFiltro}
                >
                  <Grid item xs={6}>
                    <BotaoFiltroOrdenacao
                      type="button"
                      color={theme.color.secondaryBorderColor}
                      background="transparent"
                      label="Filtrar / Ordernar"
                      icon={<TuneIcon />}
                      className={classes.buttonFiltro}
                      ordenadorUm="Ordenador"
                      ordenacaoColuna={listarOrganizarPor()}
                      ordenadorSeis="Status"
                      filtroStatus={filtroStatusPor()}
                      ordemSelecionada={ordemSelecionada}
                      ordemColunaSelecionada={ordemColunaSelecionada}
                      filtroStatusSelecionados={filtroStatusSelecionado}
                      onClickFiltroStatus={handleClickFiltroStatusSelecionado}
                      onClickSortOrder={handleClickSortOrderSelecionado}
                      onClickAtivarAgora={handleClickAtivarFiltro}
                      larguraBotao="4"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt-4">
            {mobile ? (
              <Loader loading={carregandoPdf}>
                <TabelaPaginadaMobile
                  className={classes.fundoCelulaTransparente}
                  ref={refTabela}
                  onChangeFiltrosTabela={onChangeFiltrosTabela}
                  filtrosAdicionais={filtroAdicionalTabela}
                  colunas={colunas}
                  paginaAtual={1}
                  noHeader
                />
              </Loader>
            ) : (
              <TabelaPaginada
                className={classes.tabela}
                ref={refTabela}
                onChangeFiltrosTabela={onChangeFiltrosTabela}
                colunas={colunas}
                filtrosAdicionais={filtroAdicionalTabela}
                paginaAtual={1}
                paginationPerPage={20}
                noHeader
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <UnidadeNaoSelecionada />
      )}
    </>
  );
};

export default ListagemFaturas;
