import React from "react";
import PropTypes from "prop-types";
import { Edit } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import { useStyles } from "./style";

const ColunaBaixar = ({ onClick, parametros }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={12}>
        <a
          href={`${RotasDTO.AcompanhamentoCliente}/${
            parametros.unidadeId
          }/${encodeURIComponent(parametros.clienteNome)}`}
          className={`text-white ${classes.botao}`}
          target="_blank"
          rel="noreferrer"
        >
          <HtmlTooltip title="Baixar">
            <Edit />
          </HtmlTooltip>
        </a>
      </Grid>
    </Grid>
  );
};

ColunaBaixar.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaBaixar;
