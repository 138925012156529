export default class FiltroAdicionalTabela {
  constructor(
    distribuidoraId,
    textoBusca,
    unidades,
    unidadesSelecionadas,
    statusId,
    associacaoId
  ) {
    this.distribuidoraId = distribuidoraId;
    this.textoBusca = textoBusca;
    this.unidades = unidades;
    this.unidadesSelecionadas = unidadesSelecionadas;
    this.statusId = statusId;
    this.associacaoId = associacaoId;
  }
}
