import React from "react";
import BaixarIcone from "assets/icones/baixar-icone.svg";
import Select from "../Componentes/Select";
import { CelulaOpcoes, BotaoOpcao, StyledTooltip } from "./style";

export default class ColunaFiltro {
  constructor(
    label,
    nome,
    opcoes,
    opcao,
    onSelecionar,
    campoId,
    onClickIniciar,
    minWidth = null,
    maxWidth = null
  ) {
    this.name = (
      <Select
        id={nome}
        name={nome}
        label={<strong>{label}</strong>}
        opcao={opcao}
        opcoes={opcoes}
        setOpcao={(item) => {
          onSelecionar(item);
        }}
      />
    );

    const verificarStatus = (params) => {
      return (
        <>
          <span>{params[nome]}</span>
          <StyledTooltip title="Iniciar migração" placement="top" arrow>
            <BotaoOpcao
              className="celulaBotao"
              onClick={() => onClickIniciar(params[campoId || "id"])}
            >
              <img src={BaixarIcone} alt="Ícone iniciar migração" />
            </BotaoOpcao>
          </StyledTooltip>
        </>
      );
    };

    this.selector = label;
    this.cell = (row) => <CelulaOpcoes>{verificarStatus(row)}</CelulaOpcoes>;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
  }
}
