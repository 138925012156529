import React, { useMemo } from "react";
import { Switch, Route } from "react-router-dom";

// Componentes
import RotasAreaLogada from "../../submodules/AreaLogada/rotas/rotas";
import DeltaTarifa from "../../submodules/DeltaTarifa/rotas/rotas";
import OnBoarding from "../../paginas/WorkFlow/OnBoarding";
import OnBoardingGD from "../../paginas/WorkFlow/OnBoardingGD";
import ContatoCotacao from "../../paginas/Demo/Cotacao/ContatoCotacao";
import Registro from "../../paginas/Acesso/Registro";
import Login from "../../paginas/Acesso/Login";
import SelecaoConta from "../../paginas/Acesso/SelecaoConta";
import Home from "../../paginas/Home";
import Dashboard from "../../paginas/Graficos/Dashboard";
import DashboardMobile from "../../paginas/Graficos/Dashboard/mobile";
import AlterarSenha from "../../paginas/Conta/AlterarSenha";
import PerfilAcessoListar from "../../paginas/Cadastros/PerfilAcesso/listagem";
import PerfilAcessoCadastro from "../../paginas/Cadastros/PerfilAcesso/cadastro";
import CentralNotificacoesRelatorio from "../../paginas/CeltralNotificacoes/Relatorios";
import Serasa from "../../paginas/Serasa/Listagem";
import ClienteListar from "../../paginas/Cadastros/Cliente/listagem";
import ClienteCadastro from "../../paginas/Cadastros/Cliente/cadastro";
import FuncionalidadeCadastro from "../../paginas/Cadastros/Funcionalidade";
import MedidoresCadastro from "../../paginas/Cadastros/Medidores/cadastro";
import MedidoresListar from "../../paginas/Cadastros/Medidores/listagem";
import ColaboradorCadastro from "../../paginas/Cadastros/Colaborador/cadastro";
import ColaboradorListar from "../../paginas/Cadastros/Colaborador/listagem";
import DistribuidoraListar from "../../paginas/Cadastros/Distribuidora/listagem";
import DistribuidoraCadastro from "../../paginas/Cadastros/Distribuidora/cadastro";
import UsuarioListar from "../../paginas/Cadastros/Usuario/listagem";
import UsuarioCadastro from "../../paginas/Cadastros/Usuario/cadastro";
import MedicaoUpload from "../../paginas/Upload/Medicao";
import ProinfaUpload from "../../paginas/Upload/Proinfa";
import ProcessamentoFaturaMLUpload from "../../paginas/Financeiro/ProcessamentoFaturaML/listagem";
import ProcessamentoFaturaMLDetalhes from "../../paginas/Financeiro/ProcessamentoFaturaML/detalhes";
import MedicaoConsolidada from "../../paginas/Graficos/Consolidacao";
import EmpresaGrupoDeltaCadastro from "../../paginas/Cadastros/EmpresaGrupoDelta/cadastro";
import EmpresaGrupoDeltaListar from "../../paginas/Cadastros/EmpresaGrupoDelta/listagem";
import PatamaresUpload from "../../paginas/Upload/Patamares";
import MatrizDesconto from "../../paginas/Upload/MatrizDesconto";
import TarifasUpload from "../../paginas/Upload/Tarifas";
import MunicipiosUpload from "../../paginas/Upload/Municipio";
import ArvoreGerencialCadastro from "../../paginas/Cadastros/ArvoreGerencial";
import UnidadesListar from "../../paginas/Cadastros/UnidadeConsumidora/listagem";
import UnidadesCadastro from "../../paginas/Cadastros/UnidadeConsumidora/cadastro";
import CotacaoIncialCadastro from "../../paginas/Cotacao/CotacaoInicial";
import CotacaoIncialCadastroMobile from "../../paginas/Cotacao/CotacaoInicial/mobile";
import CotacaoMobile from "../../paginas/Cotacao/CotacaoMobile";
import Agente from "../../paginas/Cadastros/Agente";
import AgenteCadastro from "../../paginas/Cadastros/Agente/cadastro";
import PlanosSimulados from "../../paginas/Cotacao/Planos";
import PerfilAgente from "../../paginas/Cadastros/PerfilAgente";
import DadosEmpresa from "../../paginas/Cotacao/DadosEmpresa";
import DadosEmpresaMobile from "../../paginas/Cotacao/DadosEmpresaMobile";
import ListagemCotacoes from "../../paginas/Cotacao/ListagemCotacoes";
import ListagemValidarCotacoes from "../../paginas/Cotacao/ListagemValidarCotacoes";
import ValidarCotacoes from "../../paginas/Cotacao/Validar";
import ListagemGrupoAssociativo from "../../paginas/GrupoAssociativo/ListagemGrupoAssociativo";
import ListagemDistribuidoraPisCofins from "../../paginas/Cadastros/DistribuidoraPisCofins/listagem";
import DistribuidoraPisCofinsCadastro from "../../paginas/Cadastros/DistribuidoraPisCofins/cadastro";
import ListagemDistribuidoraICMS from "../../paginas/Cadastros/DistribuidoraICMS/listagem";
import DistribuidoraICMSCadastro from "../../paginas/Cadastros/DistribuidoraICMS/cadastro";
import BoletaCadastro from "../../paginas/Cadastros/Boleta/cadastro";
import ListagemBoletas from "../../paginas/Cadastros/Boleta/listagem";
import GrupoAssociativoCadastro from "../../paginas/Cadastros/GrupoAssociativo/cadastro";
import PdfCotacoes from "../../paginas/Relatorios/Cotacao/PDF";
import ListagemMedicao from "../../paginas/Graficos/ListagemMedicao";
import AlteracaoMedicoes from "../../paginas/AlteracaoMedicoes/listagem";
import AlteracaoMedicoesConsolidadas from "../../paginas/AlteracaoMedicoes/Consolidadas/listagem";
import Demo from "../../paginas/Demo/Home";
import RotaUsinas from "../../paginas/Investimentos/RotaUsinas";
import DadosEnergia from "../../paginas/Demo/Cotacao/Cadastro";
import CapturaFatura from "../../paginas/Demo/Cotacao/Captura";
import MelhoresPlanos from "../../paginas/Demo/Cotacao/Planos";
import Contrato from "../../paginas/Demo/Cotacao/Contrato";
import Sucesso from "../../paginas/Demo/Cotacao/Sucesso";
import Economia from "../../paginas/Graficos/Economia";
import EconomiaMobile from "../../paginas/Graficos/Economia";
import ListagemFaturas from "../../paginas/Financeiro/Fatura/listagem";
import FaturasMobile from "../../paginas/Financeiro/Fatura/listagem/mobile";
import PreFaturamentoCadastro from "../../paginas/Cadastros/PreFaturamento/cadastro";
import PreFaturamentoRastreamento from "../../paginas/Cadastros/PreFaturamento/Rastreamento";
import PreFaturamento from "../../paginas/Cadastros/PreFaturamento/Listagem";
import LeadClienteListar from "../../paginas/Cadastros/LeadCliente/listagem";
import LeadClienteCadastro from "../../paginas/Cadastros/LeadCliente/cadastro";
import LeadUnidadesListar from "../../paginas/Cadastros/LeadUnidadeConsumidora/listagem";
import LeadUnidadesCadastro from "../../paginas/Cadastros/LeadUnidadeConsumidora/cadastro";
import CotacaoEditarDesconto from "../../paginas/Cotacao/CotacaoEditarDesconto";
import Correios from "../../paginas/Upload/Correios";
import ListagemTermos from "../../paginas/Cadastros/TermoDeUso/listagem";
import CadastroTermo from "../../paginas/Cadastros/TermoDeUso/cadastro";
import ListagemFaturasColaborador from "../../paginas/FinanceiroColaborador/Fatura/listagem";
import ListagemFaturaDistribuidoraCliente from "../../paginas/FaturaDistribuidora/Cliente/listagem";
import ListagemFaturaDistribuidoraPreLead from "../../paginas/FaturaDistribuidora/PreLead/listagem";
import BoletaCadastroGD from "../../paginas/Cadastros/BoletaGD/cadastro";
import ListagemBoletaGD from "../../paginas/Cadastros/BoletaGD/listagem";
import ResumoPrincipal from "../../paginas/ConsumoEconomia/ResumoPrincipal";
import PorHora from "../../paginas/ConsumoEconomia/PorHora";
import Diario from "../../paginas/ConsumoEconomia/Diario";
import Mensal from "../../paginas/ConsumoEconomia/Mensal";
import Geral from "../../paginas/ConsumoEconomia/Geral";
import Ajuda from "../../paginas/Ajuda";
import MedidorGD from "../../paginas/Cadastros/MedidorGD/cadastro";
import ListagemMedidorGD from "../../paginas/Cadastros/MedidorGD/listagem";
import Atual from "../../paginas/ConsumoEconomia/Atual";
import ListagemFaturasGD from "../../paginas/Financeiro/FaturaGd";
import ContaDocumentos from "../../paginas/Conta/Documentos";
import PrecosAtual from "../../paginas/Precos/Atual";
import Historico from "../../paginas/Precos/Historico";
import Constantes from "../../paginas/Precos/Constantes";
import CotacaoGD from "../../paginas/CotacaoGD/CotacaoInicialGD";
import ContaBaixarContratos from "../../paginas/Conta/BaixarContratos";
import EditarCupom from "../../paginas/Cadastros/EditarCupom";
import CotacoesGDLista from "../../paginas/CotacaoGD/ListagemCotacoesGD";
import Indices from "../../paginas/Upload/Indices";
import CotacoesGDListaSemFatura from "../../paginas/CotacaoGD/ListagemCotacoesGDSemFatura";
import CotacaoACL from "../../paginas/Cotacao/CotacaoInicialACL";
import CotacaoPlanosACL from "../../paginas/Cotacao/PlanosACL";
import PrecoAnual from "../../paginas/Precos/Anual";
import AcompanhamentoCliente from "../../paginas/Operacoes/AcompanhamentoCliente";
import AnaliseDocumento from "../../paginas/Operacoes/AnaliseDocumento";
import DadosEmpresaACL from "../../paginas/Cotacao/DadosEmpresaACL";
import Categorias from "../../paginas/Categorias";
import Questionario from "../../paginas/Questionario";
import ListagemOperacoes from "../../paginas/Operacoes/ListagemOperacoes";
import MemberGetMemberDistribuidora from "../../paginas/MemberGetMember/Distribuidora";
import MemberGetMemberFatura from "../../paginas/MemberGetMember/Fatura";
import VisaoGeral from "../../paginas/Financeiro/VisaoGeral";
import AssociacaoLuz from "../../paginas/Cadastros/AssociacaoLuz/Listagem";
import AssocicaoLuzCadastro from "../../paginas/Cadastros/AssociacaoLuz/Cadastro";
import MemberGetMemberPrecoFixo from "../../paginas/MemberGetMember/PrecoFixo";
import ScoreSerasaACL from "../../paginas/Serasa/ScoreSerasaACL";
import ScoreSerasaGD from "../../paginas/Serasa/ScoreSerasaGD";
import UsinasLuz from "../../paginas/Cadastros/UsinasLuz/Listagem";
import UsinasLuzCadastro from "../../paginas/Cadastros/UsinasLuz/Cadastro";
import ValidacaoJuridico from "../../paginas/Operacoes/ValidacaoJuridico";
import ConvideGanhe from "../../paginas/MemberGetMember/ConvideGanhe";
import Simulacoes from "../../paginas/Cotacao/Simulacoes";
import CadastroCri from "../../paginas/Acesso/CadastroCri";
import DownloadCupons from "../../paginas/MemberGetMember/DownloadCupons";
import DownloadProcuracao from "../../paginas/Conta/DownloadProcuracao";
import PowerBI from "../../paginas/PowerBI";
import UsinasUnidadeUpload from "../../paginas/Upload/UsinasUnidade";
import SuspensaoCancelamento from "../../paginas/Operacoes/SuspensaoCancelamento";
import AtualizarStatusMigracao from "../../paginas/Operacoes/Atividades/AtualizarStatusMigracao";
import VincularAssociacaoUC from "../../paginas/Operacoes/Atividades/VincularAssociacaoUC";
import IniciarMigracao from "../../paginas/Operacoes/Atividades/IniciarMigracao";

import Conteudo from "../../componentes/conteudo";
import RotaNaoEncontrada from "./rotaNaoEncontrada";

import { RotasDTO } from "./rotasUrlDto";

const Rotas = () => {
  const rotas = useMemo(() => {
    return [
      ...RotasAreaLogada,
      ...DeltaTarifa,
      {
        path: "/",
        caminho: "Home",
        component: Home,
        exact: true,
        menuCabecalho: "Home",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.DashboardConsumo,
        caminho: "DashboardConsumo",
        component: Dashboard,
        exact: true,
        menuCabecalho: "Dashboard",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.DashboardConsumo}/mobile`,
        caminho: "DashboardConsumo",
        component: DashboardMobile,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: RotasDTO.Login,
        caminho: "Login",
        component: Login,
        exact: true,
        autenticada: false,
        estruturada: false,
        esconderMenu: false
      },
      {
        path: RotasDTO.LoginSemCaptcha,
        caminho: "Login",
        component: Login,
        exact: false,
        autenticada: false,
        estruturada: false,
        esconderMenu: false
      },
      {
        path: "/logout",
        caminho: "Logout",
        component: Login,
        exact: true,
        autenticada: false,
        estruturada: false,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Login}/selecao-conta`,
        caminho: "Login",
        component: SelecaoConta,
        exact: true,
        autenticada: true,
        estruturada: false,
        esconderMenu: false
      },
      {
        path: "/registro/:token?",
        caminho: "registro",
        component: Registro,
        autenticada: false,
        estruturada: false,
        esconderMenu: false
      },
      {
        path: RotasDTO.AlterarSenha,
        caminho: "AlterarSenha",
        component: AlterarSenha,
        exact: true,
        menuCabecalho: "Segurança e senha",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.PerfisAcesso,
        caminho: "PerfisAcesso",
        component: PerfilAcessoListar,
        exact: true,
        menuCabecalho: "Perfil de acesso",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.PerfisAcesso}/cadastro/:id?`,
        caminho: "PerfisAcesso",
        component: PerfilAcessoCadastro,
        exact: true,
        menuCabecalho: "Perfil de acesso",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Clientes,
        caminho: "Clientes",
        component: ClienteListar,
        exact: true,
        menuCabecalho: "Clientes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Clientes}/cadastro/:id?`,
        caminho: "Clientes",
        component: ClienteCadastro,
        exact: true,
        menuCabecalho: "Clientes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Funcionalidades,
        caminho: "Funcionalidades",
        component: FuncionalidadeCadastro,
        exact: true,
        menuCabecalho: "Funcionalidades",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Medidores}/cadastro/:id?`,
        caminho: "Medidores",
        component: MedidoresCadastro,
        exact: true,
        menuCabecalho: "Medidores",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Medidores,
        caminho: "Medidores",
        component: MedidoresListar,
        exact: true,
        menuCabecalho: "Medidores",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Distribuidoras,
        caminho: "Distribuidoras",
        component: DistribuidoraListar,
        exact: true,
        menuCabecalho: "Distribuidoras",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Distribuidoras}/cadastro/:id?`,
        caminho: "Distribuidoras",
        component: DistribuidoraCadastro,
        exact: true,
        menuCabecalho: "Distribuidoras",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Colaboradores,
        caminho: "Colaboradores",
        component: ColaboradorListar,
        exact: true,
        menuCabecalho: "Colaboradores",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Colaboradores}/cadastro/:id?`,
        caminho: "Colaboradores",
        component: ColaboradorCadastro,
        exact: true,
        menuCabecalho: "Colaboradores",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Usuarios,
        caminho: "Usuarios",
        component: UsuarioListar,
        exact: true,
        menuCabecalho: "Usuários",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Usuarios}/cadastro/:id?`,
        caminho: "Usuarios",
        component: UsuarioCadastro,
        exact: true,
        menuCabecalho: "Usuários",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadMedicao}`,
        caminho: "UploadMedicao",
        component: MedicaoUpload,
        exact: true,
        menuCabecalho: "Upload de Medição",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadProinfa}`,
        caminho: "UploadProinfa",
        component: ProinfaUpload,
        exact: true,
        menuCabecalho: "Upload de Proinfa",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ProcessamentoFaturaML}`,
        caminho: "ProcessamentoFaturaML",
        component: ProcessamentoFaturaMLUpload,
        exact: true,
        menuCabecalho: "Processamento de Fatura ML",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ProcessamentoFaturaML}/:unidadeid/:mes/:ano`,
        caminho: "ProcessamentoFaturaML",
        component: ProcessamentoFaturaMLDetalhes,
        exact: true,
        menuCabecalho: "Processamento Detalhes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.MedicaoConsolidada}`,
        caminho: "MedicaoConsolidada",
        component: MedicaoConsolidada,
        exact: true,
        menuCabecalho: "Medição Consolidada",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.EmpresaGrupoDelta}`,
        caminho: "EmpresaGrupoDelta",
        component: EmpresaGrupoDeltaListar,
        exact: true,
        menuCabecalho: "Empresas Grupo Delta",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.EmpresaGrupoDelta}/cadastro/:id?`,
        caminho: "EmpresaGrupoDelta",
        component: EmpresaGrupoDeltaCadastro,
        exact: true,
        menuCabecalho: "Empresa Grupo Delta",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadPatamares}`,
        caminho: "UploadPatamares",
        component: PatamaresUpload,
        exact: true,
        menuCabecalho: "Upload de Patamares",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadMatrizDesconto}`,
        caminho: "UploadMatrizDesconto",
        component: MatrizDesconto,
        exact: true,
        menuCabecalho: "Upload de Matriz",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.UploadTarifas,
        caminho: "UploadTarifas",
        component: TarifasUpload,
        exact: true,
        menuCabecalho: "Upload de tarifas",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadMunicipios}`,
        caminho: "UploadMunicipios",
        component: MunicipiosUpload,
        exact: true,
        menuCabecalho: "Upload de municipios",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadCorreios}`,
        caminho: "UploadCorreios",
        component: Correios,
        exact: true,
        menuCabecalho: "Upload Base Correios",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ArvoreGerencial}`,
        caminho: "ArvoreGerencial",
        component: ArvoreGerencialCadastro,
        exact: true,
        menuCabecalho: "Arvore Gerencial",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.UnidadesConsumidoras,
        caminho: "UnidadesConsumidoras",
        component: UnidadesListar,
        exact: true,
        menuCabecalho: "Unidades Consumidoras",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UnidadesConsumidoras}/cadastro/:id?`,
        caminho: "UnidadesConsumidoras",
        component: UnidadesCadastro,
        exact: true,
        menuCabecalho: "Unidades Consumidoras",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Agentes}`,
        caminho: "Agentes",
        component: Agente,
        exact: true,
        menuCabecalho: "Agentes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Agentes}/cadastro/:id?`,
        caminho: "Agentes",
        component: AgenteCadastro,
        exact: true,
        menuCabecalho: "Agentes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Cotacoes}/planos/:id?`,
        caminho: "Cotacoes",
        component: PlanosSimulados,
        exact: true,
        menuCabecalho: "Planos",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: `${RotasDTO.CotacaoIncial}/:id?`,
        caminho: "CotacaoIncial",
        component: CotacaoIncialCadastro,
        exact: true,
        menuCabecalho: "Cotaçao Inicial",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.CotacaoIncial}//mobile/:id?`,
        caminho: "CotacaoIncial",
        component: CotacaoIncialCadastroMobile,
        exact: true,
        menuCabecalho: "Home",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: `${RotasDTO.Cotacoes}/mobile`,
        caminho: "Cotacoes",
        component: CotacaoMobile,
        exact: true,
        menuCabecalho: "Home",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: `${RotasDTO.Cotacoes}`,
        caminho: "Cotacoes",
        component: ListagemCotacoes,
        exact: true,
        menuCabecalho: "Listagem de cotações",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ValidarCotacoes}`,
        caminho: "ValidarCotacoes",
        component: ListagemValidarCotacoes,
        exact: true,
        menuCabecalho: "Validar cotações",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ValidarCotacoes}/comparar/:id?`,
        caminho: "ValidarCotacoes",
        component: ValidarCotacoes,
        exact: true,
        menuCabecalho: "Validar cotações",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Cotacoes}/pdf/:id?`,
        caminho: "Cotacoes",
        component: PdfCotacoes,
        exact: true,
        menuCabecalho: "Listagem de cotações",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.PerfilAgenteCadastro}/:id?`,
        caminho: "PerfilAgenteCadastro",
        component: PerfilAgente,
        exact: true,
        menuCabecalho: "Agentes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Boletas,
        caminho: "Boletas",
        component: ListagemBoletas,
        exact: true,
        menuCabecalho: "Boletas",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Boletas}/cadastro/:id?`,
        caminho: "Boletas",
        component: BoletaCadastro,
        exact: true,
        menuCabecalho: "Boletas",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Cotacoes}/empresa`,
        caminho: "Cotacoes",
        component: DadosEmpresa,
        exact: true,
        menuCabecalho: "Dados empresa",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Cotacoes}/empresamobile`,
        caminho: "Cotacoes",
        component: DadosEmpresaMobile,
        exact: true,
        menuCabecalho: "Dados empresa",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: `${RotasDTO.GrupoAssociativo}`,
        caminho: "GrupoAssociativo",
        component: ListagemGrupoAssociativo,
        exact: true,
        menuCabecalho: "Grupo Associativo",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.DistribuidoraPisCofins}`,
        caminho: "DistribuidoraPisCofins",
        component: ListagemDistribuidoraPisCofins,
        exact: true,
        menuCabecalho: "Distribuidora Pis/Cofins",
        autenticada: true,
        estruturada: true
      },
      {
        path: `${RotasDTO.DistribuidoraPisCofins}/cadastro/:id?`,
        caminho: "DistribuidoraPisCofins",
        component: DistribuidoraPisCofinsCadastro,
        exact: true,
        menuCabecalho: "Distribuidora Pis/Cofins",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.DistribuidoraICMS}`,
        caminho: "DistribuidoraICMS",
        component: ListagemDistribuidoraICMS,
        exact: true,
        menuCabecalho: "Distribuidora ICMS",
        autenticada: true,
        estruturada: true
      },
      {
        path: `${RotasDTO.DistribuidoraICMS}/cadastro/:id?`,
        caminho: "DistribuidoraICMS",
        component: DistribuidoraICMSCadastro,
        exact: true,
        menuCabecalho: "Distribuidora ICMS",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.GrupoAssociativo}/cadastro/:id?`,
        caminho: "GrupoAssociativo",
        component: GrupoAssociativoCadastro,
        exact: true,
        menuCabecalho: "GrupoAssociativo",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.ListagemMedicao,
        caminho: "ListagemMedicao",
        component: ListagemMedicao,
        exact: true,
        menuCabecalho: "Listar Medição",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.AlteracaoMedicoes,
        caminho: "AlteracaoMedicoes",
        component: AlteracaoMedicoes,
        exact: true,
        menuCabecalho: "AlteracaoMedicoes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.AlteracaoMedicoesConsolidadas,
        caminho: "AlteracaoMedicoesConsolidadas",
        component: AlteracaoMedicoesConsolidadas,
        exact: true,
        menuCabecalho: "AlteracaoMedicoesConsolidadas",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.OnBoarding,
        caminho: "OnBoarding",
        component: OnBoarding,
        exact: true,
        menuCabecalho: "OnBoarding Dashboard",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.OnBoardingGD,
        caminho: "OnBoardingGD",
        component: OnBoardingGD,
        exact: true,
        menuCabecalho: "OnBoarding GD",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Demo,
        caminho: "Demo",
        component: Demo,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.RotaUsinas,
        caminho: "RotaUsinas",
        component: RotaUsinas,
        exact: true,
        menuCabecalho: "Rota Usinas",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Demo}/cotacao/inicial`,
        caminho: "Demo",
        component: DadosEnergia,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Demo}/cotacao/captura`,
        caminho: "Demo",
        component: CapturaFatura,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Demo}/cotacao/planos/:id?`,
        caminho: "Demo",
        component: MelhoresPlanos,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Demo}/cotacao/contato/:id?`,
        caminho: "Demo",
        component: ContatoCotacao,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Demo}/cotacao/contrato`,
        caminho: "Demo",
        component: Contrato,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Demo}/cotacao/sucesso`,
        caminho: "Demo",
        component: Sucesso,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.LeadClientes,
        caminho: "LeadClientes",
        component: LeadClienteListar,
        exact: true,
        menuCabecalho: "LeadClientes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.FaturasCliente,
        caminho: "FaturasCliente",
        component: ListagemFaturas,
        exact: true,
        menuCabecalho: "Faturas",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Faturas}/mobile`,
        caminho: "Faturas",
        component: FaturasMobile,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: RotasDTO.FaturasColaborador,
        caminho: "FaturasColaborador",
        component: ListagemFaturasColaborador,
        exact: true,
        menuCabecalho: "Faturas (Colaborador)",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.FaturaDistribuidoraCliente,
        caminho: "FaturaDistribuidoraCliente",
        component: ListagemFaturaDistribuidoraCliente,
        exact: true,
        menuCabecalho: "Fatura Distribuidora (Cliente)",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.FaturaDistribuidoraPreLead,
        caminho: "FaturaDistribuidoraPreLead",
        component: ListagemFaturaDistribuidoraPreLead,
        exact: true,
        menuCabecalho: "Fatura Distribuidora (Pré Lead)",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.PreFaturamento}/cadastro/:id`,
        caminho: "PreFaturamento",
        component: PreFaturamentoCadastro,
        exact: true,
        menuCabecalho: "PreFaturamento",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.PreFaturamento}/rastreamento/:id`,
        caminho: "PreFaturamento",
        component: PreFaturamentoRastreamento,
        exact: true,
        menuCabecalho: "PreFaturamento",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.LeadClientes}/cadastro/:id?`,
        caminho: "LeadClientes",
        component: LeadClienteCadastro,
        exact: true,
        menuCabecalho: "LeadClientes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.LeadUnidadesConsumidoras,
        caminho: "LeadUnidadesConsumidoras",
        component: LeadUnidadesListar,
        exact: true,
        menuCabecalho: "Lead Unidades Consumidoras",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.LeadUnidadesConsumidoras}/cadastro/:id?`,
        caminho: "LeadUnidadesConsumidoras",
        component: LeadUnidadesCadastro,
        exact: true,
        menuCabecalho: "Lead Unidades Consumidoras",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Economia,
        caminho: "Economia",
        component: Economia,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true
      },
      {
        path: `${RotasDTO.Economia}/mobile`,
        caminho: "Economia",
        component: EconomiaMobile,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: RotasDTO.PreFaturamento,
        caminho: "PreFaturamento",
        component: PreFaturamento,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true
      },
      {
        path: RotasDTO.CentralNotificacoesRelatorio,
        caminho: "CentralNotificacoesRelatorio",
        component: CentralNotificacoesRelatorio,
        menuCabecalho: "Gestão",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: RotasDTO.Serasa,
        caminho: "Serasa",
        component: Serasa,
        menuCabecalho: "Gestão",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.CotacaoEditarDesconto}`,
        caminho: "CotacaoEditarDesconto",
        component: CotacaoEditarDesconto,
        exact: true,
        menuCabecalho: "Editar desconto",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Termos,
        caminho: "Termos",
        component: ListagemTermos,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true
      },
      {
        path: `${RotasDTO.Termos}/cadastro/:id?`,
        caminho: "Termos",
        component: CadastroTermo,
        exact: true,
        menuCabecalho: "Termos de Uso",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.BoletasGD,
        caminho: "BoletasGD",
        component: ListagemBoletaGD,
        exact: true,
        menuCabecalho: "Boletas GD",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.BoletasGD}/cadastro/:id?`,
        caminho: "BoletasGD",
        component: BoletaCadastroGD,
        exact: true,
        menuCabecalho: "Boletas GD",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.ConsumoEconomia,
        caminho: "ConsumoEconomia",
        component: ResumoPrincipal,
        exact: true,
        menuCabecalho: "Resumo principal",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Porhora,
        caminho: "Porhora",
        component: PorHora,
        exact: true,
        menuCabecalho: "Por hora",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Diario,
        caminho: "Diario",
        component: Diario,
        exact: true,
        menuCabecalho: "Diário",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Mensal,
        caminho: "Mensal",
        component: Mensal,
        exact: true,
        menuCabecalho: "Mensal",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Geral,
        caminho: "Geral",
        component: Geral,
        exact: true,
        menuCabecalho: "Geral",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Ajuda,
        caminho: "Ajuda",
        component: Ajuda,
        exact: true,
        menuCabecalho: "Ajuda",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.MedidorGD,
        caminho: "MedidorGD",
        component: ListagemMedidorGD,
        exact: true,
        menuCabecalho: "Medidor GD",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.MedidorGD}/cadastro/:id?`,
        caminho: "MedidorGD",
        component: MedidorGD,
        exact: true,
        menuCabecalho: "Medidor GD",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.Atual,
        caminho: "Atual",
        component: Atual,
        exact: true,
        menuCabecalho: "Atual",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.FaturasGD,
        caminho: "FaturasGD",
        component: ListagemFaturasGD,
        exact: true,
        menuCabecalho: "Faturas",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.FaturasGD}/mobile`,
        caminho: "FaturasGD",
        component: FaturasMobile,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: `${RotasDTO.CotacoesGD}/:id?`,
        caminho: "CotacoesGD",
        component: CotacaoGD,
        exact: true,
        menuCabecalho: "Cotacões GD",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.PrecosAtual}`,
        caminho: "PrecosAtual",
        component: PrecosAtual,
        exact: true,
        menuCabecalho: "Atual",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.ContaDocumentos,
        caminho: "ContaDocumentos",
        component: ContaDocumentos,
        exact: true,
        menuCabecalho: "Documentos",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.PrecosHistorico}`,
        caminho: "PrecosHistorico",
        component: Historico,
        exact: true,
        menuCabecalho: "Histórico",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.PrecosConstantes}`,
        caminho: "PrecosConstantes",
        component: Constantes,
        exact: true,
        menuCabecalho: "Constantes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.ContaBaixarContratos,
        caminho: "ContaBaixarContratos",
        component: ContaBaixarContratos,
        exact: true,
        menuCabecalho: "Baixar contratros",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.EditarCupom,
        caminho: "EditarCupom",
        component: EditarCupom,
        exact: true,
        menuCabecalho: "Editar Cupom",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.CotacoesGDListagem}`,
        caminho: "CotacoesGDListagem",
        component: CotacoesGDLista,
        exact: true,
        menuCabecalho: "Lista Cotacões",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadIndices}`,
        caminho: "UploadIndices",
        component: Indices,
        exact: true,
        menuCabecalho: "Upload Índices IPCA/IGPM",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.CotacoesGDListagemSemFatura}`,
        caminho: "CotacoesGDListagemSemFatura",
        component: CotacoesGDListaSemFatura,
        exact: true,
        menuCabecalho: "Lista Cotacões",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.AcompanhamentoCliente}/:id?/:nome?`,
        caminho: "AcompanhamentoCliente",
        component: AcompanhamentoCliente,
        exact: true,
        menuCabecalho: "Acompanhamento Cliente",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.AnaliseDocumento}/:operacaocontroleid?`,
        caminho: "AnaliseDocumento",
        component: AnaliseDocumento,
        exact: true,
        menuCabecalho: "Analise de Documento",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.CotacaoACL}/:id?`,
        caminho: "CotacaoACL",
        component: CotacaoACL,
        exact: true,
        menuCabecalho: "Cotacões ACL",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.CotacaoACL}//planos/:id?`,
        caminho: "CotacaoACL",
        component: CotacaoPlanosACL,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: `${RotasDTO.PrecosAnual}`,
        caminho: "PrecosAnual",
        component: PrecoAnual,
        exact: true,
        menuCabecalho: "Preço Anual",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.CotacaoACL}//dados-empresa/:id?`,
        caminho: "CotacaoACL",
        component: DadosEmpresaACL,
        exact: true,
        menuCabecalho: "",
        autenticada: true,
        estruturada: true,
        esconderMenu: true
      },
      {
        path: `${RotasDTO.Categorias}`,
        caminho: "Categorias",
        component: Categorias,
        exact: true,
        menuCabecalho: "Categorias",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Questionario}`,
        caminho: "Questionario",
        component: Questionario,
        exact: true,
        menuCabecalho: "Questionário",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ListarOperacoes}`,
        caminho: "ListarOperacoes",
        component: ListagemOperacoes,
        exact: true,
        menuCabecalho: "Listar Operações",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.MemberGetMemberDistribuidora}`,
        caminho: "MemberGetMemberDistribuidora",
        component: MemberGetMemberDistribuidora,
        exact: true,
        menuCabecalho: "MemberGetMemberDistribuidora",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.MemberGetMemberFatura}`,
        caminho: "MemberGetMemberFatura",
        component: MemberGetMemberFatura,
        exact: true,
        menuCabecalho: "MemberGetMemberFatura",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.VisaoGeral}`,
        caminho: "VisaoGeral",
        component: VisaoGeral,
        exact: true,
        menuCabecalho: "Visão Geral",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.AssociacaoLuz}`,
        caminho: "AssociacaoLuz",
        component: AssociacaoLuz,
        exact: true,
        menuCabecalho: "AssociacaoLuz",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.AssociacaoLuz}/cadastro/:id?`,
        caminho: "AssociacaoLuz",
        component: AssocicaoLuzCadastro,
        exact: true,
        menuCabecalho: "AssociacaoLuz",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.MemberGetMemberPrecoFixo}`,
        caminho: "MemberGetMemberPrecoFixo",
        component: MemberGetMemberPrecoFixo,
        exact: true,
        menuCabecalho: "MemberGetMemberPrecoFixo",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.ScoreSerasaACL,
        caminho: "ScoreSerasaACL",
        component: ScoreSerasaACL,
        menuCabecalho: "ScoreSerasaACL",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.ScoreSerasaGD,
        caminho: "ScoreSerasaGD",
        component: ScoreSerasaGD,
        menuCabecalho: "ScoreSerasaGD",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UsinasLuz}`,
        caminho: "UsinasLuz",
        component: UsinasLuz,
        exact: true,
        menuCabecalho: "UsinasLuz",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UsinasLuz}/cadastro/:id?`,
        caminho: "UsinasLuz",
        component: UsinasLuzCadastro,
        exact: true,
        menuCabecalho: "UsinasLuz",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ValidacaoJuridico}`,
        caminho: "ValidacaoJuridico",
        component: ValidacaoJuridico,
        exact: true,
        menuCabecalho: "ValidacaoJuridico",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.ConvideGanhe}`,
        caminho: "ConvideGanhe",
        component: ConvideGanhe,
        exact: true,
        menuCabecalho: "ConvideGanhe",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Simulacoes}`,
        caminho: "Simulacoes",
        component: Simulacoes,
        exact: true,
        menuCabecalho: "Simulacoes",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Login}/cri`,
        caminho: "Login",
        exact: true,
        component: Login,
        autenticada: false,
        estruturada: false,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.Login}/cri/cadastro`,
        caminho: "Login",
        exact: true,
        component: CadastroCri,
        autenticada: false,
        estruturada: false,
        esconderMenu: false
      },
      {
        path: RotasDTO.DownloadProcuracao,
        caminho: "DownloadProcuracao",
        exact: true,
        component: DownloadProcuracao,
        menuCabecalho: "DownloadProcuracao",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.PowerBI,
        caminho: "PowerBI",
        component: PowerBI,
        menuCabecalho: "PowerBI",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.DownloadCupons,
        caminho: "DownloadCupons",
        component: DownloadCupons,
        exact: true,
        menuCabecalho: "DownloadCupons",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: `${RotasDTO.UploadUsinasUnidade}`,
        caminho: "UploadUsinasUnidade",
        component: UsinasUnidadeUpload,
        exact: true,
        menuCabecalho: "UploadUsinasUnidade",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.SuspensaoCancelamento,
        caminho: "SuspensaoCancelamento",
        component: SuspensaoCancelamento,
        menuCabecalho: "SuspensaoCancelamento",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.AtualizarStatusMigracao,
        caminho: "AtualizarStatusMigracao",
        component: AtualizarStatusMigracao,
        menuCabecalho: "AtualizarStatusMigracao",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.VincularAssociacaoUC,
        caminho: "VincularAssociacaoUC",
        component: VincularAssociacaoUC,
        menuCabecalho: "VincularAssociacaoUC",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      },
      {
        path: RotasDTO.IniciarMigracao,
        caminho: "IniciarMigracao",
        component: IniciarMigracao,
        menuCabecalho: "IniciarMigracao",
        autenticada: true,
        estruturada: true,
        esconderMenu: false
      }
    ];
  }, [RotasDTO]);

  return (
    <Switch>
      {rotas.map((rota, r) => (
        <Conteudo key={r} {...rota} />
      ))}

      <Route component={RotaNaoEncontrada} />
    </Switch>
  );
};

export default Rotas;
