import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Botao, SelectArredondado } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import InputData from "componentes/inputTextoData";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AtualizarStatusMigracaoHelper from "../../AtualizarStatusMigracao/helper";

import { useStyles } from "./style";

const FormularioVincularAssociacao = (props) => {
  const {
    onCancelar,
    setCarregando,
    setNotificacao,
    obterDados,
    unidadesSelecionadas,
    carregando
  } = props;
  const classes = useStyles();
  const [dataFinalizacaoTroca, setDataFinalizacaoTroca] = useState();
  const [desabilitarSalvar, setDesabilitarSalvar] = useState();
  const [status, setStatus] = useState();
  const [listaMotivos, setListaMotivos] = useState();
  const [motivoProblemaId, setMotivoProblemaId] = useState();

  const obterMotivos = async () => {
    const resultado = await AtualizarStatusMigracaoHelper.listarProblemaMigracaoMotivos(
      setNotificacao
    );

    setListaMotivos(resultado?.data || []);
  };

  const onChangeMotivo = (valor) => {
    setMotivoProblemaId(valor);
  };

  const validarBotao = () => {
    if (status === 1 && dataFinalizacaoTroca) {
      setDesabilitarSalvar(false);
      return;
    }
    if ((status === 2 || status === 3) && motivoProblemaId) {
      setDesabilitarSalvar(false);
      return;
    }

    setDesabilitarSalvar(true);
  };

  const validarStatus = () => {
    if (status === 1) return "Migrado";
    if (status === 2) return "FalhaMigracao";
    if (status === 3) return "EncaminharAtendimento";

    return "FalhaMigracao";
  };

  const onSalvar = async () => {
    setCarregando(true);
    setDesabilitarSalvar(true);

    const resultadoAtualizar = await AtualizarStatusMigracaoHelper.atualizarTrocaTitularidade(
      {
        operacoesControleId: unidadesSelecionadas,
        ...(dataFinalizacaoTroca && {
          dataFinalizacaoTroca
        }),
        tipoAtualizacaoTroca: validarStatus(),
        ...(motivoProblemaId && {
          motivoProblemaId: Number(motivoProblemaId)
        })
      },
      setNotificacao
    );

    setCarregando(false);
    setDesabilitarSalvar(false);

    if (resultadoAtualizar?.data) {
      setNotificacao({
        aberto: true,
        tipo: "warning",
        texto:
          unidadesSelecionadas?.length > 1
            ? `${unidadesSelecionadas?.length} UCs estão em processamento!`
            : `${unidadesSelecionadas?.length} UC esta em processamento!`
      });

      onCancelar();
      obterDados({ quantidade: unidadesSelecionadas?.length, status });
    }
  };

  const onChangeStatus = async (valor) => {
    setDataFinalizacaoTroca();
    setMotivoProblemaId();

    setStatus(Number(valor?.target?.value));
  };

  useEffect(() => {
    validarBotao();
  }, [dataFinalizacaoTroca, status, motivoProblemaId]);

  useEffect(() => {
    obterMotivos();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        {unidadesSelecionadas && (
          <Grid item xs={12} className={classes.inputRadio}>
            <FormControl>
              <RadioGroup
                name="radio-buttons-group"
                value={status}
                onChange={onChangeStatus}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Migrado"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Falha na migração, reinicie o processo"
                />
                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label="Encaminhar para atendimento/parceiros"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {(status === 2 || status === 3) && (
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="motivos" className={classes.label}>
              Motivo do problema
            </label>
            <SelectArredondado
              select
              id="motivos"
              name="motivos"
              label="Selecione uma opção"
              placeholder="Selecione uma opção"
              valueKey="id"
              valueName="descricao"
              dataSource={listaMotivos}
              value={motivoProblemaId}
              onChange={(e) => {
                onChangeMotivo(e.target.value);
              }}
              permiteValorBranco
            />
          </Grid>
        )}
        {status === 1 && (
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="motivos" className={classes.label}>
              Data de conclusão de migração
            </label>
            <InputData
              type="text"
              id="dataMigracao"
              name="dataMigracao"
              label="DD/MM/AAAA"
              customValue={dataFinalizacaoTroca}
              onChange={(data) => setDataFinalizacaoTroca(data)}
              renderIconShowHide={false}
              className={classes}
              placement="top"
            />
          </Grid>
        )}

        <Grid item xs={6} mt={6}>
          <BotaoOutLine
            onClick={() => onCancelar()}
            label="Cancelar"
            className={classes.botaoTransparente}
            disabled={carregando}
          />
        </Grid>
        <Grid item xs={6} mt={6}>
          <Botao
            onClick={() => onSalvar()}
            label="Salvar"
            className={classes.botao}
            disabled={desabilitarSalvar || carregando}
          />
        </Grid>
      </Grid>
    </>
  );
};

FormularioVincularAssociacao.propTypes = {
  onCancelar: PropTypes.func,
  setCarregando: PropTypes.func,
  setNotificacao: PropTypes.func,
  obterDados: PropTypes.func,
  unidadesSelecionadas: PropTypes.arrayOf(PropTypes.number),
  carregando: PropTypes.bool
};

FormularioVincularAssociacao.defaultProps = {
  onCancelar: () => {},
  setCarregando: () => {},
  setNotificacao: () => {},
  obterDados: () => {},
  unidadesSelecionadas: [],
  carregando: false
};

export default FormularioVincularAssociacao;
