import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo
} from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";

import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput
} from "@mui/material";
import shortid from "shortid";
import FiltroPesquisaPaginada from "./filtroPesquisaPaginada";
import PaginacaoTabela from "../paginacaoTabela";
import TabelaSemDados from "./tabelaSemDados";
import CarregandoTabela from "./carregandoTabela";

// Styles
import { customStyles, useStyles, conditionalRowStyles } from "./style";

const TabelaPaginada = forwardRef(
  (
    {
      onChangeFiltrosTabela,
      colunas,
      paginaAtual,
      filtrosAdicionais,
      pesquisar,
      filtroPersonalizado,
      noHeader,
      className,
      noFooter,
      totalItens,
      itemsPorPagina,
      noDataComponent,
      persistTableHead
    },
    ref
  ) => {
    const [linhas, setLinhas] = useState([]);
    const [pagina, setPagina] = useState(paginaAtual || 1);
    const [quantidadeLinhas, setQuantidadeLinhas] = useState(
      itemsPorPagina[0] || itemsPorPagina
    );
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [filtro, setFiltro] = useState(
      new FiltroPesquisaPaginada(
        1,
        itemsPorPagina[0] || itemsPorPagina,
        null,
        null,
        "",
        {},
        {}
      )
    );
    const [loadingQueue, setLoadingQueue] = useState([]);
    const loading = useMemo(() => loadingQueue && loadingQueue.length > 0, [
      loadingQueue
    ]);

    const classe = useStyles();

    const rowsPerPageOptions = [quantidadeLinhas];

    const passarPagina = (parametros) => {
      if (parametros === pagina) return;

      setPagina(parametros);

      setFiltro((oldState) => {
        if (!oldState)
          return new FiltroPesquisaPaginada(parametros, quantidadeLinhas);

        return new FiltroPesquisaPaginada(
          parametros,
          quantidadeLinhas,
          oldState.colunaOrdenacao,
          oldState.ordenacao,
          oldState.pesquisar,
          oldState.filtrosAdicionais,
          oldState.filtroPersonalizado
        );
      });
    };

    const pesquisarPorString = useCallback(() => {
      // Se pesquisa for por números, então iniciar pesquisa pelo segundo dígito
      const regex = /[0-9]/;
      const checarValor = regex.test(pesquisar);
      let valorPesquisa;
      if (checarValor) {
        valorPesquisa = pesquisar?.length < 2 ?? true ? "" : pesquisar;
      } else {
        valorPesquisa = pesquisar?.length < 3 ?? true ? "" : pesquisar;
      }

      if (valorPesquisa === filtro.pesquisar) return;

      setFiltro(
        (oldState) =>
          new FiltroPesquisaPaginada(
            paginaAtual,
            oldState.totalPagina,
            oldState.colunaOrdenacao,
            oldState.ordenacao,
            valorPesquisa,
            oldState.filtrosAdicionais,
            oldState.filtroPersonalizado
          )
      );
    }, [pesquisar, setFiltro, filtro]);

    const setFiltrosAdicionais = useCallback(() => {
      if (
        JSON.stringify(filtrosAdicionais) ===
        JSON.stringify(filtro?.filtrosAdicionais)
      )
        return;

      setFiltro(
        (oldState) =>
          new FiltroPesquisaPaginada(
            paginaAtual,
            oldState.totalPagina,
            oldState.colunaOrdenacao,
            oldState.ordenacao,
            oldState.pesquisar,
            filtrosAdicionais,
            oldState.filtroPersonalizado
          )
      );
    }, [filtrosAdicionais, setFiltro, filtro]);

    const setFiltroPersonalizado = useCallback(() => {
      if (
        JSON.stringify(filtroPersonalizado) ===
        JSON.stringify(filtro?.filtroPersonalizado)
      )
        return;

      setFiltro(
        (oldState) =>
          new FiltroPesquisaPaginada(
            paginaAtual,
            oldState.totalPagina,
            oldState.colunaOrdenacao,
            oldState.ordenacao,
            oldState.pesquisar,
            oldState.filtrosAdicionais,
            filtroPersonalizado
          )
      );
    }, [filtroPersonalizado, setFiltro, filtro]);

    const ordenarColuna = (coluna, direcao) => {
      setLinhas(orderBy(linhas, coluna?.selector, direcao));
      setPagina(paginaAtual);
    };

    const buscarDadosPaginados = useCallback(async () => {
      const reqId = shortid.generate();

      try {
        setLoadingQueue((oldState) => [...oldState, reqId]);
        const resultado = await onChangeFiltrosTabela(filtro);

        if (!resultado) {
          setLinhas([]);
          setTotalRegistros(0);
          return;
        }

        setLinhas(resultado.linhas);
        setTotalRegistros(resultado.totalItens);
      } catch (error) {
        setLinhas([]);
        setTotalRegistros(0);
      } finally {
        setLoadingQueue((oldState) =>
          oldState.filter((x) => String(x) !== String(reqId))
        );
      }
    }, [filtro]);

    const handleChangeItems = (valor) => {
      setQuantidadeLinhas(valor?.target?.value);
      setFiltro(
        (oldState) =>
          new FiltroPesquisaPaginada(
            1,
            valor?.target?.value,
            oldState.colunaOrdenacao,
            oldState.ordenacao,
            oldState.valorPesquisa,
            oldState.filtrosAdicionais,
            oldState.filtroPersonalizado
          )
      );
    };

    useEffect(() => {
      buscarDadosPaginados();
    }, [buscarDadosPaginados]);

    useEffect(() => {
      setFiltrosAdicionais();
    }, [setFiltrosAdicionais]);

    useEffect(() => {
      setFiltroPersonalizado();
    }, [setFiltroPersonalizado]);

    useEffect(() => {
      pesquisarPorString();
    }, [pesquisarPorString]);

    useImperativeHandle(ref, () => ({
      obterDadosPaginados() {
        buscarDadosPaginados();
      }
    }));

    return (
      <div
        className={className}
        style={{ display: "flex", height: "100%", width: "100%" }}
      >
        <div style={{ flexGrow: 1, overflowX: "scroll" }}>
          <DataTable
            columns={colunas}
            data={linhas}
            noHeader={noHeader}
            page={pagina}
            pagination
            paginationServer
            noRowsPerPage
            sortServer
            progressPending={loading}
            theme="DeltaEnergia"
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            paginationTotalRows={totalRegistros}
            paginationPerPage={quantidadeLinhas}
            progressComponent={
              <CarregandoTabela
                className={`${classe.Container} ${noDataComponent?.className}`}
              />
            }
            noDataComponent={
              <TabelaSemDados
                className={`${classe.Container} ${noDataComponent?.className}`}
                texto={noDataComponent?.texto}
              />
            }
            paginationRowsPerPageOptions={rowsPerPageOptions}
            onChangePage={(parametros) => passarPagina(parametros)}
            onSort={ordenarColuna}
            onChangeRowsPerPage={(linhasPorPagina, pagAtual) => {
              setQuantidadeLinhas(linhasPorPagina);
              setPagina(pagAtual);
            }}
            persistTableHead={persistTableHead}
            paginationComponent={(parametros) => {
              return (
                <Grid container>
                  {noFooter ? (
                    <>
                      {itemsPorPagina?.length > 0 && (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          lg={3}
                          display="flex"
                          alignItems="center"
                          style={{ paddingTop: 20 }}
                        >
                          <InputLabel
                            id="itemsPorPagina"
                            className={classe.label}
                          >
                            Itens por página
                          </InputLabel>
                          <FormControl>
                            <Select
                              labelId="itemsPorPagina"
                              id="itemsSelect"
                              value={quantidadeLinhas}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              input={<OutlinedInput className={classe.input} />}
                              onChange={handleChangeItems}
                            >
                              {itemsPorPagina?.map((item) => (
                                <MenuItem value={item} key={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={itemsPorPagina?.length > 0 ? 8 : 12}
                        lg={itemsPorPagina?.length > 0 ? 6 : 12}
                        display="flex"
                        justifyContent={{ lg: "center" }}
                        alignItems="center"
                        style={{ padding: 20 }}
                      >
                        <PaginacaoTabela
                          totalRegistros={parametros.rowCount}
                          onChangePagina={parametros.onChangePage}
                          pagina={parametros.currentPage}
                          registrosPorPagina={
                            quantidadeLinhas || parametros.rowsPerPage
                          }
                          totalItens={totalItens}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              );
            }}
          />
        </div>
      </div>
    );
  }
);

TabelaPaginada.propTypes = {
  onChangeFiltrosTabela: PropTypes.func.isRequired,
  colunas: PropTypes.oneOfType([PropTypes.array]).isRequired,
  paginaAtual: PropTypes.number.isRequired,
  pesquisar: PropTypes.string,
  filtrosAdicionais: PropTypes.oneOfType([PropTypes.any]),
  filtroPersonalizado: PropTypes.oneOfType([PropTypes.any]),
  noHeader: PropTypes.bool,
  className: PropTypes.string,
  noFooter: PropTypes.bool,
  totalItens: PropTypes.bool,
  itemsPorPagina: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  noDataComponent: PropTypes.shape({
    texto: PropTypes.node,
    className: PropTypes.string
  }),
  persistTableHead: PropTypes.bool
};

TabelaPaginada.defaultProps = {
  pesquisar: "",
  filtrosAdicionais: {},
  filtroPersonalizado: {},
  noFooter: true,
  noHeader: false,
  className: "",
  totalItens: false,
  itemsPorPagina: 10,
  noDataComponent: {},
  persistTableHead: false
};

export default TabelaPaginada;
