export default class OperacaoDto {
  constructor(
    nomeUnidade,
    id,
    nomeAssociacao,
    disabled,
    operacaoControleId,
    associacaoId
  ) {
    this.nomeUnidade = nomeUnidade;
    this.id = id;
    this.nomeAssociacao = nomeAssociacao;
    this.disabled = disabled;
    this.operacaoControleId = operacaoControleId;
    this.associacaoId = associacaoId;
  }
}
