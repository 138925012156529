import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import theme from "themes";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const useStyles = makeStyles(() => ({
  input: {
    borderColor: "transparent !important",
    "& .MuiInputBase-input": {
      padding: 0,
      borderColor: "transparent !important",
      textOverflow: "initial !important",
      whiteSpace: "inherit !important",
      overflow: "initial !important",
      lineHeight: "16px !important"
    },
    "& fieldset": {
      borderColor: "transparent !important",
      "& :focus-visible": {
        borderColor: "transparent"
      }
    },
    "& .MuiSelect-icon": {
      color: theme.color.primary
    }
  },
  menu: {
    "& ul": {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}));

export const Menu = styled(MenuItem)`
  color: ${(props) => (props.ativo ? "#FFF !important" : "#4f4f4f !important")};
  background-color: ${(props) =>
    props.ativo ? `${theme.color.primary} !important` : "#FFF !important"};
  opacity: 1 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  padding: 16px !important;
  text-transform: uppercase;
`;
