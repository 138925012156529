import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

// Componentes
import { Grid, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";

// Icones
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import HelpIcon from "@mui/icons-material/Help";

// Form Hooks
import NumberFormat from "react-number-format";
import { Controller, useForm } from "react-hook-form";

// Styles
import { useStyles } from "./style";

const MaterialInputMascara = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    type,
    defaultValue,
    renderIconShowHide,
    errors,
    style,
    className,
    searchAdornment,
    helperLabel,
    mask,
    onBlur,
    onInputChange,
    readOnly,
    limparValor,
    redefineLimpar,
    disabled,
    sufixo,
    prefixo,
    thousandSeparator,
    onKeyDown,
    cleanAdornment,
    onClickLimpar
  } = props;

  const { control, setValue } = useForm();
  const [typeInput, setTypeInput] = useState(type);

  useEffect(() => {
    if (defaultValue || defaultValue === 0) setValue(`${name}`, defaultValue);
  }, [defaultValue, setValue, name]);

  const onClickShowPassword = () => {
    setTypeInput(typeInput === "text" ? "password" : "text");
  };

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const showAdornment = (show) => {
    const adornment = { startAdornment: null, endAdornment: null };
    if (cleanAdornment && !show && defaultValue) {
      adornment.endAdornment = (
        <InputAdornment position="end">
          <IconButton onClick={onClickLimpar} edge="end" size="large">
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    if (searchAdornment) {
      adornment.startAdornment = (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      );
    }
    if (show && !searchAdornment && !cleanAdornment) {
      adornment.endAdornment = (
        <InputAdornment position="end">
          <IconButton
            onClick={onClickShowPassword}
            onMouseDown={onMouseDownPassword}
            edge="end"
            size="large"
          >
            {typeInput === "text" ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      );
    }
    return adornment?.startAdornment || adornment?.endAdornment
      ? adornment
      : null;
  };

  const classes = useStyles();

  const CustomMask = useCallback(
    (customProps) => {
      const { onChange, ...rest } = customProps;
      if (limparValor) {
        onChange("");
        redefineLimpar(false);
      }

      const customMask =
        mask === null
          ? ""
          : {
              ...(mask && { format: mask }),
              ...(!mask && {
                thousandSeparator: thousandSeparator,
                decimalSeparator: ",",
                fixedDecimalScale: true,
                allowedDecimalSeparators: [","]
              })
            };

      return (
        <NumberFormat
          {...rest}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange(values?.value);
            onInputChange(values?.value)
          }}
          {...customMask}
          prefix={prefixo}
          suffix={sufixo}
          isNumericString
          onBlur={onBlur}
          onFocus={() => ref?.current?.focus()}
          readOnly={readOnly}
        />
      );
    },
    [limparValor, redefineLimpar, mask, onBlur, readOnly, prefixo, sufixo]
  );

  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item lg={helperLabel ? 11 : 12} xs={helperLabel ? 11 : 12}>
        <Controller
          as={TextField}
          control={control}
          id={id}
          name={name}
          variant="outlined"
          label={label}
          type={typeInput}
          InputProps={{
            inputComponent: CustomMask,
            ...showAdornment(renderIconShowHide),
            autoComplete: "nope"
          }}
          defaultValue={defaultValue || ""}
          classes={{ root: className?.length ? className : classes.root }}
          error={Boolean(errors[name]?.message)}
          helperText={errors[name]?.message ?? ""}
          fullWidth
          style={style}
          autoComplete="off"
          disabled={disabled}
          onKeyDown={onKeyDown}
        />
      </Grid>
      {helperLabel ? (
        <Grid item lg={1}>
          <Tooltip title={helperLabel} aria-label={helperLabel}>
            <HelpIcon />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
});

MaterialInputMascara.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderIconShowHide: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.any]),
  searchAdornment: PropTypes.bool,
  helperLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  mask: PropTypes.string,
  onBlur: PropTypes.oneOfType([PropTypes.func]),
  onKeyDown: PropTypes.oneOfType([PropTypes.func]),
  onInputChange: PropTypes.oneOfType([PropTypes.func]),
  readOnly: PropTypes.bool,
  limparValor: PropTypes.bool,
  redefineLimpar: PropTypes.oneOfType([PropTypes.func]),
  disabled: PropTypes.bool,
  sufixo: PropTypes.string,
  prefixo: PropTypes.string,
  thousandSeparator: PropTypes.string,
  cleanAdornment: PropTypes.bool,
  onClickLimpar: PropTypes.func
};

MaterialInputMascara.defaultProps = {
  label: "",
  id: "",
  type: "text",
  defaultValue: "",
  renderIconShowHide: false,
  errors: {},
  style: {},
  className: "",
  searchAdornment: false,
  helperLabel: "",
  mask: "",
  onBlur: () => {},
  onKeyDown: () => {},
  onInputChange: () => {},
  readOnly: false,
  limparValor: false,
  redefineLimpar: () => {},
  disabled: false,
  sufixo: "",
  prefixo: "",
  thousandSeparator: ".",
  cleanAdornment: false,
  onClickLimpar: () => {}
};

export default MaterialInputMascara;
