import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Botao } from "componentes";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import InputData from "componentes/inputTextoData";
import BotaoOutLine from "componentes/botaoOutline";
import IniciarMigracaoHelperHelper from "../../IniciarMigracao/helper";

import { useStyles } from "./style";

const FormularioAtualizarStatus = (props) => {
  const {
    onCancelar,
    setCarregando,
    setNotificacao,
    obterDados,
    unidadesSelecionadas,
    carregando,
    unidadeSelecionada
  } = props;
  const [cor, setCor] = useState("#9F9F9F");
  const classes = useStyles({ cor });
  const [desabilitarSalvar, setDesabilitarSalvar] = useState();
  const [protocolo, setProtocolo] = useState();
  const [dataPrevisaoConclusao, setDataPrevisaoConclusao] = useState();

  const validarBotao = () => {
    if (protocolo && dataPrevisaoConclusao) {
      setDesabilitarSalvar(false);
      return;
    }

    setDesabilitarSalvar(true);
  };

  const onSalvar = async () => {
    setCarregando(true);
    setDesabilitarSalvar(true);

    const dados = {
      operacoesControleIds: unidadeSelecionada
        ? [unidadeSelecionada]
        : unidadesSelecionadas,
      protocolo,
      dataPrevisaoConclusao
    };

    const resultadoAtualizar = await IniciarMigracaoHelperHelper.iniciarMigracao(
      dados,
      setNotificacao
    );

    setCarregando(false);
    setDesabilitarSalvar(false);

    if (resultadoAtualizar?.data) {
      setNotificacao({
        aberto: true,
        tipo: "warning",
        texto: unidadeSelecionada
          ? "1 UC esta em processamento!"
          : unidadesSelecionadas?.length > 1
          ? `${unidadesSelecionadas?.length} UCs estão em processamento!`
          : `${unidadesSelecionadas?.length} UC esta em processamento!`
      });

      onCancelar();
      obterDados({
        status: 1,
        quantidade: unidadeSelecionada ? 1 : unidadesSelecionadas?.length
      });
    }
  };

  useEffect(() => {
    validarBotao();
  }, [protocolo, dataPrevisaoConclusao]);

  return (
    <Grid container mt={1}>
      <Grid item xs={12} pt={2} className="font-weight-bold">
        <label htmlFor="associacao" className={classes.label}>
          Protocolo da distribuidora
        </label>
        <MaterialInputTexto
          type="text"
          id="protocolo"
          name="protocolo"
          placeholder="Digite o protocolo da distribuidora"
          renderIconShowHide={false}
          defaultValue={protocolo || ""}
          onBlur={(e) => setProtocolo(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} pt={2} className="font-weight-bold">
        <label htmlFor="motivos" className={classes.label}>
          Data prevista de conclusão de migração
        </label>
        <InputData
          type="text"
          id="dataMigracao"
          name="dataMigracao"
          placeholder="DD/MM/AAAA"
          customValue={dataPrevisaoConclusao}
          onChange={(data) => {
            setCor("#4F4F4F");
            setDataPrevisaoConclusao(data);
          }}
          renderIconShowHide={false}
          className={classes}
          placement="top"
        />
      </Grid>
      <Grid item xs={6} pt={3} pr={1}>
        <BotaoOutLine
          onClick={() => onCancelar()}
          label="Cancelar"
          className={classes.botaoTransparente}
          disabled={carregando}
        />
      </Grid>
      <Grid item xs={6} pt={3}>
        <Botao
          onClick={() => onSalvar()}
          label="Salvar"
          className={classes.botao}
          disabled={desabilitarSalvar || carregando}
        />
      </Grid>
    </Grid>
  );
};

FormularioAtualizarStatus.propTypes = {
  onCancelar: PropTypes.func,
  setCarregando: PropTypes.func,
  setNotificacao: PropTypes.func,
  obterDados: PropTypes.func,
  unidadesSelecionadas: PropTypes.arrayOf([PropTypes.number]),
  carregando: PropTypes.bool,
  unidadeSelecionada: PropTypes.oneOfType(PropTypes.number)
};

FormularioAtualizarStatus.defaultProps = {
  onCancelar: () => {},
  setCarregando: () => {},
  setNotificacao: () => {},
  obterDados: () => {},
  unidadesSelecionadas: [],
  carregando: false,
  unidadeSelecionada: null
};

export default FormularioAtualizarStatus;
